// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
// import logoblack from "../../assets/fpsc-logo.png";
// import httpClient from "../../services/httpClient.ts";
// import loginGraphic from '../../assets/login-graphic.png';
// // import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import API_URLS from "./../../constants/apiUrls.ts";
// import styles from "./Login.module.css";
// import { Toast, ToastContainer } from "react-bootstrap";
// // import { useIntercom } from "react-use-intercom";
// import { jwtDecode } from "jwt-decode";
// import { useUser } from "../../context/UserContext.js"

// const Login = () => {
// const { login } = useUser()

//   const {
//     register,
//     clearErrors,
//     handleSubmit,
//     reset,
//     formState: { errors },
//   } = useForm({ mode: "all" });
//   const navigate = useNavigate();
//   // const { boot } = useIntercom()
//   const [errorMessage, setErrorMessage] = useState(null);

//   // Function to show dynamic error message
//   const showError = (message) => {
//     setErrorMessage(message);
//   };

// //   const fetchUserPermissions = (id) => {

// //     httpClient({
// //         method: "get",
// //         url: API_URLS.getPermissions.replace("{id}", id),
// //     })
// //         .then(({ result, error }) => {
// //             if (result) {
// //                 setPermissions(result?.role?.permissions);
// //             }
// //         })
// //         .catch((error) => {
// //             console.error("Error:", error);
// //         });
// // }

//   const handleLogin = (data) => {
//     let url = API_URLS.login;
//     httpClient({
//       method: "post",
//       url,
//       data,
//     }).then(({ result, error }) => {
//       if (result) {
//         localStorage.setItem("user", JSON.stringify(result?.user));
//         localStorage.setItem("token", result?.token);
//         const decodedToken = jwtDecode(result?.token);

//         login(result?.user, decodedToken)
//         const permissions = decodedToken?.role?.permissions
//         let route
//         if (permissions.includes("establishmentInsights") || permissions.includes("full")) {
//           route = "/establishment-data";
//         } else if (permissions.includes("educationalProgramInsights") || permissions.includes("full")) {
//           route = "/educational-program";
//         } else if (permissions.includes("workforceInsights") || permissions.includes("full")) {
//           route = "/employment-data";
//         } else if (decodedToken?.role?.role === 'ADMIN') {
//           route = "/settings";
//         }
//         navigate(route);
//         reset();
//         // boot({ name: result?.user?.name })
//       } else {
//         showError(error.message);
//       }
//     }).catch((error) => {
//       console.error("Error:", error);
//       showError(error.message);
//     }).finally(() => {
//     });
//   };
//   return (
//     <>
//       <div className={styles.login_container}>
//         <div className={styles.login_header}>
//           <div className="container-fluid">
//             <img src={logoblack} alt="" style={{ width: "154px" }} />
//           </div>
//         </div>
//         <div className={`container`}>
//           <ToastContainer
//             style={{ top: "20px", right: "20px" }}
//             className="toast-error"
//           >
//             {errorMessage && (
//               <Toast
//                 onClose={() => setErrorMessage(null)}
//                 show={true}
//                 delay={3000}
//                 autohide
//               >
//                 <Toast.Header>
//                   <strong className="me-auto">Error</strong>
//                 </Toast.Header>
//                 <Toast.Body>{errorMessage}</Toast.Body>
//               </Toast>
//             )}
//           </ToastContainer>
//           <div className="row no-gutters" style={{padding: "0 20 !important"}}>
//             <div
//               className={`align-items-center bg-white col-md-6 d-flex`}
//             >
//               <div className={styles.login_form}>
//                 <div className="brand  form-style">
//                   <h1>
//                     Effortlessly upload and oversee your documents all in one
//                     place.
//                   </h1>
//                   <p className={styles.login_form_info}>
//                     Welcome! Please enter your email and password
//                   </p>

//                   <Form className="mb-5" onSubmit={handleSubmit(handleLogin)}>
//                     <div className="custom-form-group">
//                       <label>Email *</label>
//                       <input
//                         type="text"
//                         placeholder="Enter your email address"
//                         className="c-form-control c-form-control-lg"
//                         id="InputEmail"
//                         aria-describedby="emailHelp"
//                         {...register("email", {
//                           required: {
//                             value: true,
//                             message: "Required",
//                           },
//                           pattern: {
//                             value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
//                             message: "Invalid email address",
//                           },
//                         })}
//                       />

//                       {errors.email && (
//                         <div className="invalid-feedback">
//                           {errors.email?.message}
//                         </div>
//                       )}
//                     </div>
//                     <div className="custom-form-group">
//                       <label>Password *</label>
//                       <input
//                         type="password"
//                         placeholder="Enter Password"
//                         className="c-form-control c-form-control-lg"
//                         id="InputPassword"
//                         {...register("password", {
//                           required: "Required",
//                           // pattern: {
//                           //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
//                           //     message: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long',
//                           // },
//                         })}
//                       />
//                       {errors.password && (
//                         <div className="invalid-feedback">
//                           {errors.password.message}
//                         </div>
//                       )}
//                     </div>
//                     <button type="submit" className="button">
//                       Login
//                     </button>
//                   </Form>
//                 </div></div>
//             </div>
//             <div className="col-md-6">
//               <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Login;


import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/esm/Form";
// import logo from "../../assets/Logo.png";
import logoblack from "../../assets/fpsc-logo.png";
import httpClient from "../../services/httpClient.ts";
import loginGraphic from '../../assets/login-graphic.png';
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API_URLS from "./../../constants/apiUrls.ts";
import styles from "./Login.module.css";
import { Modal, Button } from "react-bootstrap";
// import { useIntercom } from "react-use-intercom";
import { jwtDecode } from "jwt-decode";
import { useUser } from "../../context/UserContext.js"
import { Loader } from "../../components/Loader.tsx";

const Login = () => {
  const { login } = useUser()

  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: resetForm2, watch, formState: { errors: errorsForm2 } } = useForm();

  const navigate = useNavigate();
  // const { boot } = useIntercom()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
  const [signupSuccessMessage, setSignupSuccessMessage] = useState(null);
  const [isSignup, setIsSignup] = useState(false);

  // Function to show dynamic error message
  const showError = (message) => {
    setErrorMessage(message);
  };


  const handleLogin = async (data :any) => {
    try {
      const url = API_URLS.login;
      
      // API Call
      const { result, error } = await httpClient({
        method: "post",
        url,
        data,
      });
  
      if (!result?.status) {
        toast.error(error?.message || "Login failed.");
        return;
      }
  
      // Save user and token to localStorage
      const { user, token, plan } = result;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
  
      // Set preferred language
      const preferredLanguage = user?.preferredLanguage || "en";
      localStorage.setItem("selectedLanguage", preferredLanguage);
  
      // Decode token and login user
      const decodedToken = jwtDecode(token);
      login(user, decodedToken, plan);
  
      // Navigate based on user role and plan
      if (user?.role === "SUPER_ADMIN") {
        navigate("/establishment-data");
      } else if (user?.role === "USER") {
        navigate(plan ? "/establishment-data" : "/subscription-plans");
      }
  
      // Reset the form
      reset();
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error.message || "An error occurred during login.");
    }
  };
  
  const handleSignup = async (data: any) => {
    try {
      setIsLoading(true);
  
      const url = API_URLS.userSignUp; // Replace with your signup API endpoint
      const response = await httpClient({
        method: "post",
        url,
        data,
      });
  
      const { result, error } = response;
  
      console.log(result ,error)
      if (result?.status) {
        toast.success(result.message || "Signup successful!");
        setIsSignup(false); // Switch to login form after successful signup
        setShowSuccessModal(true);
        resetForm2();
      } else {
        toast.error(error?.message || "Signup failed. Please try again.");
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error(err?.message || "Signup failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [showSuccessModal, setShowSuccessModal]);

  
  return (
    <>
      <ToastContainer />
      <Loader show={isLoading} />
      <div className={styles.login_container}>
        <div className={styles.login_header}>
          <div className="container-fluid">
            <img src={logoblack} alt="" style={{ width: "154px" }} />
          </div>
        </div>
        <div className={`container`}>
          {/* <ToastContainer
            style={{ top: "20px", right: "20px" }}
            className="toast-error"
          >
            {errorMessage && (
              <Toast
                onClose={() => setErrorMessage(null)}
                show={true}
                delay={3000}
                autohide
              >
                <Toast.Header>
                  <strong className="me-auto">Error</strong>
                </Toast.Header>
                <Toast.Body>{errorMessage}</Toast.Body>
              </Toast>
            )}
          </ToastContainer> */}
          <div className="row no-gutters" style={{ padding: "0 20 !important" }}>
            <div
              className={`align-items-center bg-white col-md-7 d-flex`}
            >
              <div className={styles.login_form}>
                {!isSignup
                  ?
                  <div className="brand form-style">
                    <h1>
                      Effortlessly upload and oversee your documents all in one
                      place.
                    </h1>
                    <p className={styles.login_form_info}>
                      Welcome! Please enter your email and password
                    </p>

                    <Form className="mb-5" onSubmit={handleSubmit(handleLogin)}>
                      <div className="custom-form-group">
                        <label>Email *</label>
                        <input
                          type="text"
                          placeholder="Enter your email address"
                          className="c-form-control c-form-control-lg"
                          id="InputEmail"
                          aria-describedby="emailHelp"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Required",
                            },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />

                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email?.message?.toString()}
                          </div>
                        )}
                      </div>
                      <div className="custom-form-group">
                        <label>Password *</label>
                        <input
                          type="password"
                          placeholder="Enter Password"
                          className="c-form-control c-form-control-lg"
                          id="InputPassword"
                          {...register("password", {
                            required: "Required",
                          })}
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {errors.password.message?.toString()}
                          </div>
                        )}
                      </div>
                      <button type="submit" className="button">
                        Login
                      </button>
                    </Form>
                    <p>
                      Don’t have an account?{" "}
                      <span
                        className={styles.toggle_link}
                        style={{ color: "#e61e28", cursor: "pointer" }}
                        onClick={() => setIsSignup(true)}
                      >
                        Create new account
                      </span>
                    </p>
                  </div>
                  :
                  <div className="brand form-style">
                    <h1 style={{ color: "#e61e28", marginBottom: "30px" }}>
                      Create Your FPSC Account
                    </h1>

                    <Form className="mb-5" onSubmit={handleSubmitForm2(handleSignup)}>
                      <div className="row">
                        {/* First Name */}
                        <div className="col-md-6 custom-form-group">
                          <label>First Name *</label>
                          <input
                            type="text"
                            placeholder="Enter First Name"
                            className="c-form-control c-form-control-lg"
                            id="InputFirstName"
                            aria-describedby="firstName"
                            {...registerForm2("firstName", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.firstName && (
                            <div className="invalid-feedback">{errorsForm2.firstName?.message?.toString()}</div>
                          )}
                        </div>

                        {/* Last Name */}
                        <div className="col-md-6 custom-form-group">
                          <label>Last Name *</label>
                          <input
                            type="text"
                            placeholder="Enter Last Name"
                            className="c-form-control c-form-control-lg"
                            id="InputLastName"
                            aria-describedby="lastName"
                            {...registerForm2("lastName", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.lastName && (
                            <div className="invalid-feedback">{errorsForm2.lastName?.message?.toString()}</div>
                          )}
                        </div>
                      </div>

                      {/* Email */}
                      <div className="row">
                        <div className="col-12 custom-form-group">
                          <label>Email *</label>
                          <input
                            type="text"
                            placeholder="Enter your email address"
                            className="c-form-control c-form-control-lg"
                            id="InputEmail"
                            aria-describedby="emailHelp"
                            {...registerForm2("email", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          {errorsForm2.email && (
                            <div className="invalid-feedback">{errorsForm2.email?.message?.toString()}</div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 custom-form-group">
                          <label>Password *</label>
                          <input
                            type="password"
                            placeholder="Enter Password"
                            className="c-form-control c-form-control-lg"
                            id="InputPassword"
                            {...registerForm2("password", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.password && (
                            <div className="invalid-feedback">{errorsForm2.password?.message?.toString()}</div>
                          )}
                        </div>
                      </div>

                      {/* Other Fields */}
                      <div className="row">
                        {/* Job Title */}
                        <div className="col-md-6 custom-form-group">
                          <label>Job Title *</label>
                          <input
                            type="text"
                            placeholder="Enter Job Title"
                            className="c-form-control c-form-control-lg"
                            id="InputJobTitle"
                            {...registerForm2("jobTitle", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.jobTitle && (
                            <div className="invalid-feedback">{errorsForm2.jobTitle?.message?.toString()}</div>
                          )}
                        </div>

                        {/* Preferred Language */}
                        <div className="col-md-6 custom-form-group">
                          <label>Preferred Language *</label>
                          <select
                            className="form-select c-form-control c-form-control-bg c-form-control-sm"
                            style={{ height: "48px" }}
                            id="InputPreferredLanguage"
                            {...registerForm2("preferredLanguage", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          >
                            <option value="">Select Language</option>
                            <option value="en">English</option>
                            <option value="fr">French</option>
                          </select>
                          {errorsForm2.preferredLanguage && (
                            <div className="invalid-feedback">{errorsForm2.preferredLanguage?.message?.toString()}</div>
                          )}
                        </div>
                      </div>

                      {/* Organization/Business */}
                      <div className="row">
                        <div className="col-12 custom-form-group">
                          <label>Organization/Business *</label>
                          <input
                            type="text"
                            placeholder="Enter Organization/Business"
                            className="c-form-control c-form-control-lg"
                            id="InputOrganization"
                            {...registerForm2("organization", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.organization && (
                            <div className="invalid-feedback">{errorsForm2.organization?.message?.toString()}</div>
                          )}
                        </div>
                      </div>

                      {/* Phone Number with Ext */}
                      <div className="row">
                        <div className="col-md-6 custom-form-group">
                          <label>Phone No *</label>
                          <input
                            type="text"
                            placeholder="Enter Phone No"
                            className="c-form-control c-form-control-lg"
                            id="InputPhone"
                            {...registerForm2("phone", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.phone && (
                            <div className="invalid-feedback">{errorsForm2.phone?.message?.toString()}</div>
                          )}
                        </div>

                        <div className="col-md-6 custom-form-group">
                          <label>Ext</label>
                          <input
                            type="text"
                            placeholder="Ext"
                            maxLength="5"
                            className="c-form-control c-form-control-lg"
                            id="InputExt"
                            {...registerForm2("ext")}
                          />
                        </div>
                      </div>

                      {/* Business Mailing Address */}
                      <div className="row">
                        <div className="col-12 custom-form-group">
                          <label>Business Mailing Address *</label>
                          <input
                            type="text"
                            placeholder="Enter Mailing Address"
                            className="c-form-control c-form-control-lg"
                            id="InputAddress"
                            {...registerForm2("address", {
                              required: {
                                value: true,
                                message: "Required",
                              },
                            })}
                          />
                          {errorsForm2.address && (
                            <div className="invalid-feedback">{errorsForm2.address?.message?.toString()}</div>
                          )}
                        </div>
                      </div>


                      <button type="submit" className="button">
                        Sign Up
                      </button>
                    </Form>

                    <p>
                      Already have an account?{" "}
                      <span
                        className={styles.toggle_link}
                        style={{ color: "#e61e28", cursor: "pointer" }}
                        onClick={() => setIsSignup(false)}
                      >
                        Log in here
                      </span>
                    </p>
                  </div>
                }
              </div>
            </div>
            <div className="col-md-5">
              <img src={loginGraphic} className="img-fluid float-end" alt="login graphic" />
            </div>
          </div>
        </div>
      </div>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You have successfully signed up! A verification email has been sent to your email address. Please check your inbox to verify your email.</p>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button style={{backgroundColor: "#e61e28"}} type="submit" onClick={() => setShowSuccessModal(false)}>
          OK
        </Button>
      </Modal.Footer> */}
      </Modal>
    </>
  )
}

export default Login;
