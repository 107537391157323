export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeString = (str) => {
  if(str?.includes(" ")){
    const words = str.split(" ");
  
    const capitalizedWords = words.map((word) => {
      return word?.charAt(0)?.toUpperCase() + word.slice(1);
    });
    
    return capitalizedWords.join(" ");
  }else{
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
  
}

export const toCamelCase = (str) => {
  // If the string has no spaces, return it unchanged
  if (!/\s/.test(str)) {
    return str;
  }
  
  // Remove special characters and commas
  str = str.replace(/[^a-zA-Z0-9\s]/g, '');
  
  // Convert to camel case
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}
