import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

export default function Payment({ clientSecret }) {
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState({});
  const [status, setStatus] = useState('');

  async function confirmation() {
    if (!stripe || !clientSecret) {
      // Stripe hasn't loaded or clientSecret is not available yet
      return;
    }

    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret);

      if (error) {
        console.error('Payment confirmation error:', error.message);
        setStatus(`Payment failed: ${error.message}`);
      } else if (paymentIntent) {
        setPaymentIntent(paymentIntent);
        setStatus(paymentIntent.status);
      }
    } catch (err) {
      console.error('Unexpected error during payment confirmation:', err);
    }
  }

  useEffect(() => {
    confirmation();
  }, [stripe, clientSecret]); // Ensure this only runs when both `stripe` and `clientSecret` are ready

  return (
    <div>
      <p>Payment Status: {status}</p>
    </div>
  );
}
