import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaQuestionCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface CommonHeaderProps {
    title: string;
    subtitle?: string;
    buttonTitle?: string | "";
    ButtonIcon?: React.ReactNode;
    buttonEventTriggered?: () => void;
    helpText?: string | "";
    component?: string | "";
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ title, subtitle, buttonTitle = "", ButtonIcon, buttonEventTriggered, helpText, component, onStart }) => {
    const { t } = useTranslation()
    const excludedComponents = ["documents", "addUser", "userRolePermission", "settings"];

    return (
        <>
            <header className="c_header">
                <div className="row align-items-center">
                    <div className="col-md-6 col-sm-6 col-12 order-first order-md-first">
                        <h2>{title}</h2>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 order-last order-md-last text-md-end">
                        {buttonTitle && (
                            <button
                                className="button"
                                onClick={buttonEventTriggered}
                            >
                                {typeof ButtonIcon === 'string' ? (
                                    <img src={ButtonIcon} className="inline-block align-middle me-2 icon-margin" alt="icon" />
                                ) : (
                                    <span style={{ marginRight: '8px', fontSize: '15px' }}>{ButtonIcon}</span>
                                )}
                                {buttonTitle}
                            </button>
                        )}
                        <div className='source-header d-flex align-items-center float-md-end'>
                            {subtitle && <h6>{subtitle}</h6>}
                            {!excludedComponents.includes(component as string) && (
                                <>
                                    <div className="vertical-line"></div>
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => (
                                            <Tooltip {...props} className="tooltip-justify">
                                                {/* <div style={{background: "#fff", borderRadius: "15px", width: "100%", padding:"15px", marginBottom:"5px"}}>
                                                <img src={FPSCLogo} alt="" width={"100%"} height={100}/>
                                            </div> */}

                                                {/* {t(helpText as string).split('\\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && (
                                                            <div style={{ height: '3px' }} />)
                                                            }
                                                    </React.Fragment>
                                                ))} */}
                                                {t("textClickHelp")}
                                            </Tooltip>
                                        )}
                                        placement="bottom"
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '4px',
                                                width: "40px",
                                                height: "40px",
                                                backgroundColor: "#f0f0f0",
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FaQuestionCircle style={{ fontSize: '20px' }} onClick={() => onStart(true)} />
                                        </div>
                                    </OverlayTrigger>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default CommonHeader;