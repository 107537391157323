import React, { useState } from "react";
import { Form, Toast } from "react-bootstrap";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "./../../constants/apiUrls.ts";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  const { t } = useTranslation()
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangePassword = async (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New password and confirm new password must match.");
      return;
    }
   
    try {
      setLoading(true);

      const { result ,error } = await httpClient({
        method: "post",
        url: API_URLS.changePassword,
        data: {
          oldPassword: currentPassword,
          newPassword: confirmNewPassword,
        },
      });

      if (result) {
        toast.success("Succesfully changed the password");
        //setSuccessMessage("Succesfully changed the password");
        resetForm();
      } else {
        toast.error(error?.message || "Failed to change password");
        //setErrorMessage(error?.message || "Failed to change password");
        setSuccessMessage("");

      }
    } catch (error: any) {
      console.log(error)
      toast.error(error?.message || "Failed to change password");
      //setErrorMessage(error?.message || "Failed to change password");
    } finally {
      setLoading(false);
    }
  };



  const resetForm = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "currentPassword":
        setCurrentPassword(value);
        break;
      case "newPassword":
        setNewPassword(value);
        break;
      case "confirmNewPassword":
        setConfirmNewPassword(value);
        break;
    }
  };

  return (
    <>
      <div className="pt-3 ps-5 pe-2">
        <h6 className="py-3 px-2">
          {t('settings.tabChangePassword.title')}
        </h6>
        {/* <CommonHeader title={} /> */}
      </div>

      <ToastContainer
        style={{ top: "20px", right: "20px" }}
        className="toast-error"
      >
        {/* {errorMessage && (<Toast onClose={() => setErrorMessage(null)} show={true} delay={3000} autohide>
                  <Toast.Header>
                      <strong className="me-auto">Error</strong>
                  </Toast.Header>
                  <Toast.Body>{errorMessage}</Toast.Body>
              </Toast>
              )} */}
      </ToastContainer>
      <div className="pt-3 p-5">
        <div className="change_password_form">
          <Form onSubmit={onChangePassword}>
            <div className="custom-form-group">
              <label className="text-black">{t('settings.tabChangePassword.inputTitle.currentPassword')} *</label>
              <input
                type="password"
                placeholder={t('settings.tabChangePassword.inputPlaceholder.currentPassword')}
                className="c-form-control c-form-control-bg"
                id="oldPassword"
                aria-describedby="oldPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={handleChange}
                autoComplete="off"
                required
              // {...register("email", {
              //     required: {
              //         value: true,
              //         message: "Required",
              //     },
              //     pattern: {
              //         value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              //         message: 'Invalid email address',
              //     },
              // })}
              />

              {/* {errors.email && (
                                  <div className="invalid-feedback">{errors.email?.message}</div>
                              )} */}
            </div>
            <div className="custom-form-group">
              <label className="text-black">{t('settings.tabChangePassword.inputTitle.newPassword')} *</label>
              <input
                type="password"
                placeholder={t('settings.tabChangePassword.inputPlaceholder.newPassword')}
                className="c-form-control c-form-control-bg"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                autoComplete="off"
                required
              // {...register("password", {
              //     required: 'Required',
              //     // pattern: {
              //     //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //     //     message: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long',
              //     // },
              // })}
              />
              {/* {errors.password && (
                                  <div className="invalid-feedback">{errors.password.message}</div>
                              )} */}
            </div>
            <div className="custom-form-group">
              <label className="text-black">{t('settings.tabChangePassword.inputTitle.confirmNewPassword')} *</label>
              <input
                type="password"
                placeholder={t('settings.tabChangePassword.inputPlaceholder.confirmNewPassword')}
                className="c-form-control c-form-control-bg"
                id="confirmNewPassword"
                name="confirmNewPassword"
                value={confirmNewPassword}
                onChange={handleChange}
                autoComplete="off"
                required
              // {...register("password", {
              //     required: 'Required',
              //     // pattern: {
              //     //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              //     //     message: 'Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and be at least 8 characters long',
              //     // },
              // })}
              />
              {/* {errors.password && (
                                  <div className="invalid-feedback">{errors.password.message}</div>
                              )} */}
            </div>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
            {successMessage && (
              <div className="text-success">{successMessage}</div>
            )}
            <button type="submit" className="button mt-2">
              {t('settings.tabChangePassword.buttonTitle.updatePassword')}
            </button>
          </Form>
        </div>
      </div></>
  );
};

export default ChangePassword;
