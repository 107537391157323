import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoLanguage } from "react-icons/io5";
import API_URLS from "../constants/apiUrls.ts";
import httpClient from "../services/httpClient.ts";

function LanguageSelector() {
  const { i18n } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const [language, setLanguage] = useState(user?.preferredLanguage || "en");
  
  
  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage)
    localStorage.setItem('selectedLanguage', selectedLanguage)
  };

  const fetchTranslations = async (lang) => {
    let url = API_URLS.getTranslationFile.replace("{language}", lang)
    const response = httpClient({
      method: "get",
      url,
    })
    .then(({ result, error }) => {
      if (error) {
        return // Call with error if the request fails
      }
      return result
    })
    
    return response;
};
  const loadTranslations = async (lang) => {
    const translations = await fetchTranslations(lang);
    if(translations) {
      i18n.addResourceBundle(lang, 'translation', translations);
      i18n.changeLanguage(lang);
    } 
}

  useEffect(() => {
    loadTranslations(language)
  }, [i18n,localStorage.getItem('selectedLanguage')]);

  return (
    <div className="language-select" >
      <IoLanguage style={{ fontSize: "1.5rem" }} />
      <select onChange={handleLanguageChange} style={{ width: "100%" }} value={language}>
        <option value='en'>English</option>
        <option value='fr'>Français</option>
      </select>
    </div>
  );
}

export default LanguageSelector;
