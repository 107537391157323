import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaPen } from "react-icons/fa";
import { FiSave } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Modal, Button, Form } from 'react-bootstrap';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../services/commonFn.js';
import moment from 'moment';
import visaLogo from "../assets/visa.png";
import mastercardLogo from "../assets/mastercard.svg";
// import amexLogo from "./assets/amex.png";
import defaultLogo from "../assets/default-card.png";
import { useUser } from "../context/UserContext"
import httpClient from "../services/httpClient.ts";
import API_URLS from "../constants/apiUrls.ts";
import SubscriptionPlans from './Subscription.tsx';

const AccountInfo: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const stripe = useStripe()
    const elements = useElements()
    const { user, userPlan, updateUserPlan } = useUser();
    const {
        register,
        clearErrors,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm({ mode: "all" });
    const dropdownRef = useRef(null);

    interface CardDetailInterface {
        paymentMethodId: string,
        card: any,
        billingDetails: any,
        isDefault?: boolean
    }

    const [showCardModal, setShowCardModal] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [cards, setCards] = useState<CardDetailInterface[]>([])
    const [cardHolderName, setCardHolderName] = useState<string>("")
    const [cardType, setCardType] = useState("default")
    const [dropdownIndex, setDropdownIndex] = useState(null);
    const [isEditing, setIsEditing] = useState({ name: false, email: false });
    const [formData, setFormData] = useState({
        name: user?.name || `${user.firstName} ${user.lastName}`,
        email: user?.email || '',
    });


    // Get appropriate card logo
    const getCardLogo = (cardType) => {
        switch (cardType) {
            case "visa":
                return visaLogo;
            case "mastercard":
                return mastercardLogo;
            //   case "amex":
            //     return amexLogo;
            default:
                return defaultLogo;
        }
    };

    const handleCardNumber = (e) => {
        setCardType(e.brand)
    }

    const fetchCustomerCards = async () => {
        if (user) {
            const data = {
                userId: user?._id
            }
            httpClient({
                method: "post",
                url: API_URLS.fetchCustomerCards,
                data,
            })
                .then(({ result, error }) => {
                    if (error) {
                        toast.error(error);
                        return
                    }
                    if (result.status) {
                        setCards(result.cards)
                        // toast.success("Card added successfully");
                    } else {
                        setCards([])
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                })
        }
    }

    const handleCardSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            alert('Stripe is not loaded yet!');
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement)
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardNumberElement,
                billing_details: {
                    // address: {
                    //     city: null,
                    //     country: null,
                    //     line1: null,
                    //     line2: null,
                    //     postal_code: null,
                    //     state: null
                    // },
                    email: user?.email || "",
                    name: user?.name || "",
                    // phone: null
                },
            });

            if (error) {
                console.error('Error creating payment method:', error.message);
                alert(`Error: ${error.message}`);
            } else {
                console.log('Payment Method created:', paymentMethod);
                // Send paymentMethod.id to your backend
                const data = {
                    userId: user._id,
                    paymentMethodId: paymentMethod.id
                }
                httpClient({
                    method: "post",
                    url: API_URLS.addCardToStripe,
                    data,
                })
                    .then(({ result, error }) => {
                        if (result.status) {
                            setShowCardModal(false);
                            setCardHolderName("")
                            setCardType('default')
                            setCards(result.cards)
                            toast.success("Card Added successfully");
                        } else {
                            toast.error(error);
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    })
            }
        } catch (err) {
            console.error('Error:', err.message);
        }
    };

    const toggleDropdown = (index) => {
        setDropdownIndex(dropdownIndex === index ? null : index);
    };

    const handleSetDefault = (paymentMethodId) => {
        console.log('Set default card:', paymentMethodId);
        const data = {
            userId: user._id,
            paymentMethodId
        }
        httpClient({
            method: "post",
            url: API_URLS.setDefaultCard,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error);
                    return
                }
                if (result.status) {
                    setCards(result.cards)
                    toast.success("Card made default successfully");
                } else {
                    setCards([])
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    };

    const handleDeleteCard = (paymentMethodId) => {
        console.log('Delete card:', paymentMethodId);
        const data = {
            userId: user._id,
            paymentMethodId
        }
        httpClient({
            method: "post",
            url: API_URLS.detachPaymentMethod,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error);
                    return
                }
                if (result.status) {
                    setCards(result.cards)
                    toast.success("Card detached successfully");
                } else {
                    setCards([])
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    };

    const handleEditToggle = (field) => {
        setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSaveName = () => {
        setIsEditing((prev) => ({ ...prev, name: false }));
    };

    const handleSaveEmail = () => {
        setIsEditing((prev) => ({ ...prev, email: false }));
    };

    const handleChangePassword = async (data) => {

        httpClient({
            method: "post",
            url: API_URLS.changePassword,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error);
                    return
                }
                if (result.status) {
                    setShowChangePasswordModal(false)
                    reset()
                    // updateUserPlan(result.plan)
                    toast.success(result.message);
                } else {
                    toast.error(result.message)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })

    }

    const handleCancelSubscription = () => {
        const data = {
            userId: user._id,
            userSubscriptionId: userPlan._id
        }
        httpClient({
            method: "post",
            url: API_URLS.cancelUserSubscription,
            data,
        })
            .then(({ result, error }) => {
                if (error) {
                    toast.error(error);
                    return
                }
                if (result.status) {
                    updateUserPlan(result.plan)
                    setShowConfirmationModal(false)
                    toast.success(result.message);
                } else {
                    toast.error(result.message)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
    }

    useEffect(() => {
        fetchCustomerCards()
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="flex h-100 p-3" style={{ backgroundColor: "#fff", overflowY: "scroll" }}>
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-2xl font-bold">Account details</h1>
                </div>

                <div className="row gap-2 w-100 mt-5">
                    <div className="col-12 col-md-3">
                        <h5 className="font-semibold mb-4">PROFILE DETAILS</h5>

                        {/* Full Name */}
                        <div className="mb-4">
                            <label className="block text-gray-600 mb-2">Full name</label>
                            <div
                                className="d-flex align-items-center justify-content-between bg-gray-100 p-2 gap-2 rounded"
                                style={{ minWidth: "290px", backgroundColor: '#f8f8f8' }}
                            >
                                <span className="flex-1" style={{ width: '100%' }}>
                                    {user?.name || `${capitalizeFirstLetter(user?.firstName)} ${capitalizeFirstLetter(user?.lastName)}`}
                                </span>
                                {/* {isEditing.name ? (
                                    <input
                                        type="text"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        className="form-control flex-1"
                                        style={{ width: '100%' }}
                                    />
                                ) : (
                                    <span className="flex-1" style={{ width: '100%' }}>
                                        {user?.name || `${user.firstName} ${user.lastName}`}
                                    </span>
                                )} */}
                                {/* {isEditing.name ? (
                                    <FiSave
                                        className="text-gray-600"
                                        onClick={handleSaveName}
                                        style={{ cursor: 'pointer', fontSize: "20px", color: "#1467d4" }}
                                    />
                                ) : (
                                    <FaPen
                                        className="text-gray-600"
                                        onClick={() => handleEditToggle('name')}
                                        style={{ cursor: 'pointer' }}
                                    />
                                )} */}
                            </div>
                        </div>

                        {/* Email Address */}
                        <div className="mb-4">
                            <label className="block text-gray-600 mb-2">Email address</label>
                            <div
                                className="d-flex align-items-center justify-content-between bg-gray-100 gap-2 p-2 rounded"
                                style={{ minWidth: "290px", backgroundColor: '#f8f8f8' }}
                            >
                                <span className="flex-1">{user?.email || ''}</span>
                                {/* {isEditing.email ? (
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="form-control flex-1"
                                        style={{ width: '100%' }}
                                    />
                                ) : (
                                    <span className="flex-1">{user?.email || ''}</span>
                                )}
                                {isEditing.email ? (
                                    <FiSave
                                        className="text-gray-600"
                                        onClick={handleSaveEmail}
                                        title='Save'
                                        style={{ cursor: 'pointer', fontSize: "20px", color: "#1467d4" }}
                                    />
                                ) : (
                                    <FaPen
                                        className="text-gray-600"
                                        onClick={() => handleEditToggle('email')}
                                        title='Edit'
                                        style={{ cursor: 'pointer' }}
                                    />
                                )} */}
                            </div>
                        </div>

                        {/* Password */}
                        <div className="mb-4">
                            <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => setShowChangePasswordModal(true)}>Change Password</button>
                        </div>
                    </div>
                    {/* <div className="col-12 col-md-3">
                        <h5 className="font-semibold mb-4">PROFILE DETAILS</h5>
                        <div className="mb-4">
                            <label className="block text-gray-600 mb-2">Full name</label>
                            <div className="d-flex items-center justify-content-between bg-gray-100 p-2 rounded" style={{ width: "300px", backgroundColor: "#f8f8f8" }}>
                                <span className="flex-1" style={{ width: "100%" }}>{user?.name || ""}</span>
                                <FaPen className="text-gray-600" />

                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-600 mb-2">Email address</label>
                            <div className="d-flex items-center justify-content-between bg-gray-100 p-2 rounded" style={{ width: "300px", backgroundColor: "#f8f8f8" }}>
                                <span className="flex-1">{user?.email || ""}</span>
                                <FaPen className="text-gray-600" />

                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-600 mb-2">Password</label>
                            <div className="d-flex items-center justify-content-between bg-gray-100 p-2 rounded" style={{ width: "300px", backgroundColor: "#f8f8f8" }}>
                                <span className="flex-1">●●●●●●●●</span>
                                <FaPen className="text-gray-600" />

                            </div>
                        </div>
                    </div> */}
                    <div className="col-1 d-none d-md-flex justify-content-center align-items-center">
                        <div className="border-start h-100"></div>
                    </div>
                    <div className="col-12 col-md-4">
                        <h5 className="font-semibold mb-4">SUBSCRIPTION DETAILS</h5>
                        <div className="d-flex gap-2">
                            {userPlan ?
                                <div className="bg-gray-100 p-6 rounded w-100">
                                    <h3 className="text-xl font-bold mb-4">Public</h3>
                                    {/* <p className="text-2xl font-bold mb-4">{(typeof userPlan?.subscriptionPlanId?.price === "number") ? 'Paid' : 'Free'}</p> */}
                                    <p className="text-2xl font-bold mb-4">{(userPlan?.subscriptionPlanId?.price && typeof userPlan?.subscriptionPlanId?.price == "number") ? `$${userPlan?.subscriptionPlanId?.price}` : `Free`}<span className="text-lg font-normal">{(userPlan?.subscriptionPlanId?.price && typeof userPlan?.subscriptionPlanId?.price == "number") ? `/${userPlan?.subscriptionPlanId?.recurrence}` : ``}</span></p>
                                    <p className="text-gray-600 mb-2">Subscription ending date</p>
                                    <p className="font-semibold mb-4">{moment(userPlan?.validUpTo).format('MMMM DD, YYYY')}</p>
                                    {/* <p className="text-gray-600 mb-4">This subscription allows up to 1 linked account. You are currently using 1 account.</p> */}
                                    <div className='d-flex justify-content-between gap-2 w-100 flex-wrap'>
                                        <button type='submit' className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ width: "48%", minWidth: "110px", backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => navigate('/subscription-plans')}>{!userPlan ? 'Choose Plan' : 'Upgrade Subscription'}</button>
                                        <button type='submit' className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ width: "48%", minWidth: "110px" }} onClick={() => setShowConfirmationModal(true)}>{'Cancel Subscription'}</button>
                                    </div>

                                </div>
                                :
                                <div className="bg-gray-100 p-6 rounded w-100">
                                    <p>No active plan</p>
                                    <div className='d-flex gap-2 w-100'>
                                        <button type='submit' className="w-50 bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{ backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none" }} onClick={() => navigate('/subscription-plans')}>{!userPlan ? 'Choose Plan' : 'Upgrade Subscription'}</button>
                                        {/* <button type='submit' className="w-50 bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={() => setShowConfirmationModal(true)} disabled={!userPlan}>{'Cancel Subscription'}</button> */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-1 d-none d-md-flex justify-content-center align-items-center">
                        <div className="border-start h-100"></div>
                    </div>
                    {/* <div className="col-12 col-md-3">
                        <h5 className="font-semibold mb-4">PAYMENT CARD DETAILS</h5>
                        {
                            (!cards || cards.length === 0) &&
                            (
                                <div>
                                    <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" style={{backgroundColor: "#e61e28", color: "#fff", outline: "#e61e28", border: "none"}} onClick={() => setShowCardModal(true)}>Add a Card</button>
                                </div>
                            )
                        }
                        {
                            cards && cards.length > 0 &&
                            (
                                <div>
                                    <ul>
                                        {
                                            cards && cards.length > 0 ?
                                                cards.map((card, index) => (
                                                    <li key={index} style={styles.cardItem}>
                                                        <div style={styles.cardDetails}>

                                                            <img
                                                                src={getCardLogo(card?.card?.brand)}
                                                                alt="Card Type"
                                                                style={styles.cardListLogo}
                                                            />

                                                            <div style={styles.cardInfo}>
                                                                <div style={styles.cardNumber}>
                                                                    **** **** **** {card?.card?.last4}
                                                                </div>
                                                                <div style={styles.cardName}>
                                                                    {card?.billingDetails?.name || "Cardholder Name"}
                                                                </div>
                                                                <div style={styles.cardExpiry}>
                                                                    <div>Expires: {card?.card?.exp_month}/{card?.card?.exp_year}</div>
                                                                    {card?.isDefault && (
                                                                        <span style={styles.defaultChip}>Default</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div style={styles.menuWrapper}>
                                                                <button style={styles.menuButton} onClick={() => toggleDropdown(index)}>
                                                                    &#x22EE;
                                                                </button>
                                                                {dropdownIndex === index && (
                                                                    <div style={styles.dropdown}>
                                                                        <div style={styles.dropdownItem} onClick={() => { handleSetDefault(card.paymentMethodId); toggleDropdown(index) }}>
                                                                            Set as Default
                                                                        </div>
                                                                        <div style={styles.dropdownItem} onClick={() => { handleDeleteCard(card.paymentMethodId); toggleDropdown(index) }}>
                                                                            Delete Card
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>

                                                ))
                                                :
                                                <li>
                                                    <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={() => setShowCardModal(true)}>Add a Card</button>
                                                </li>
                                        }
                                        <li>
                                            <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={() => setShowCardModal(true)}>Add a Card</button>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }

                    </div> */}
                </div>
                <Modal
                    show={showCardModal}
                    onHide={() => setShowCardModal(false)}
                    // size="lg"
                    dialogClassName="modal-90w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t('addCard')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex align-items-center justify-content-center'>
                            <div style={styles.inputGroup}>
                                <div style={styles.inputGroup}>
                                    <label>Card Holder Name</label>
                                    <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                                        <input
                                            type="text"
                                            placeholder="Enter Card Holder Name"
                                            value={cardHolderName}
                                            onChange={(e) => setCardHolderName(e.target.value)}
                                            // maxLength={19} // Maximum length for most card numbers
                                            style={styles.input}
                                        />

                                    </div>
                                </div>
                                <div style={styles.inputGroup}>
                                    <label>Card Number</label>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#f7f7f7", width: "400px", marginRight: "5px" }}>
                                        <div style={{ padding: "10px", width: "350px" }}>
                                            <CardNumberElement options={{ style }} onChange={(e) => handleCardNumber(e)} />
                                        </div>
                                        <img
                                            src={getCardLogo(cardType)}
                                            alt="Card Type"
                                            style={styles.cardLogo}
                                        />
                                    </div>
                                </div>
                                <div style={styles.inputGroup}>
                                    <label>Expiry</label>
                                    <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                                        <CardExpiryElement />
                                    </div>
                                </div>
                                <div style={styles.inputGroup}>
                                    <label>CVC</label>
                                    <div style={{ padding: "10px", backgroundColor: "#f7f7f7", width: "400px" }}>
                                        <CardCvcElement />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={handleCardSubmit}>Add Card</button>
                        <button className="bg-gray-200 text-gray-600 py-2 px-4 rounded" onClick={() => setShowCardModal(false)}>Close</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showChangePasswordModal} onHide={() => { setShowChangePasswordModal(false); clearErrors(); reset() }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            {/* Current Password */}
                            <div className="mb-3">
                                <label htmlFor="currentPassword" className="form-label">
                                    Current Password
                                </label>
                                <input
                                    type="password"
                                    id="currentPassword"
                                    className={`form-control ${errors.currentPassword ? "is-invalid" : ""}`}
                                    {...register("currentPassword", {
                                        required: "Current password is required",
                                    })}
                                />
                                {errors.currentPassword && (
                                    <div className="invalid-feedback">{errors.currentPassword.message}</div>
                                )}
                            </div>

                            {/* New Password */}
                            <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                                    {...register("newPassword", {
                                        required: "New password is required",
                                        minLength: {
                                            value: 8,
                                            message: "Password must be at least 8 characters long",
                                        },
                                    })}
                                />
                                {errors.newPassword && (
                                    <div className="invalid-feedback">{errors.newPassword.message}</div>
                                )}
                            </div>

                            {/* Confirm Password */}
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`}
                                    {...register("confirmPassword", {
                                        required: "Confirm password is required",
                                        validate: (value) =>
                                            value === watch('newPassword') || "Passwords do not match",
                                    })}
                                />
                                {errors.confirmPassword && (
                                    <div className="invalid-feedback">{errors.confirmPassword.message}</div>
                                )}
                            </div>

                            <Button type="submit" className="w-100 btn btn-primary">
                                Change Password
                            </Button>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showConfirmationModal}
                    onHide={() => setShowConfirmationModal(false)}
                    backdrop='static'
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to cancel the subscription?
                    </Modal.Body>
                    <Modal.Footer >

                        <Button type="submit" className="w-25 btn btn-primary me-2" onClick={() => handleCancelSubscription()}>
                            Yes
                        </Button>
                        <Button type="submit" className="w-25 btn btn-primary" onClick={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default AccountInfo

const style = {
    base: {
        // Style to make it look like a standard input field
        color: "#495057", // Text color
        fontFamily: "'Arial', sans-serif", // Font family
        fontSize: "16px", // Font size
        fontWeight: "normal", // Font weight
        backgroundColor: "#f7f7f7", // White background
        "::placeholder": {
            color: "#6c757d", // Placeholder text color
            opacity: 1, // Ensure placeholder is fully opaque
        },
    },
    invalid: {
        // Styling for invalid input
        color: "#fa755a", // Red text color for invalid input
        borderColor: "#fa755a", // Red border on invalid input
    },
};

const styles: Record<string, React.CSSProperties> = {
    inputGroup: {
        display: "flex",
        // alignItems: "center",
        gap: "10px",
        flexDirection: "column"
    },
    cardLogo: {
        width: "50px",
        height: "22px",
        objectFit: "contain", // Ensure the aspect ratio is maintained
        display: "block", // Ensures the image is treated as a block element
    },
    input: {
        width: "300px",
        padding: "5px",
        fontSize: "16px",
        border: "none",
        outline: "none",
        backgroundColor: "#f7f7f7"
        // borderRadius: "5px",
    },
    cardNumberGroup: {
        backgroundColor: "#fff",
        width: "400px",
        padding: "5px 10px",
        fontSize: "16px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "space-between",
    },
    cardDetails: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    cardListLogo: {
        width: '40px',
        height: 'auto',
        marginRight: '15px',
    },
    cardInfo: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    cardNumber: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '5px',
        color: '#333',
    },
    cardName: {
        fontSize: '14px',
        color: '#555',
        marginBottom: '5px',
    },
    cardExpiry: {
        fontSize: '14px',
        color: '#666',
        display: 'flex',
        // flexDirection: 'column', // Arrange items vertically
        alignItems: 'flex-start', // Align text and chip to the left
        gap: '45px', // Add space between "Expires" and "Default" chip
    },
    defaultChip: {
        backgroundColor: '#cbf5fd',
        color: '#1467d4',
        fontSize: '12px',
        padding: '2px 8px',
        borderRadius: '12px',
        fontWeight: 'bold',
    },
    cardItem: {
        position: 'relative', // Needed for dropdown positioning
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        marginBottom: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
    },
    menuWrapper: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    menuButton: {
        background: 'none',
        border: 'none',
        fontSize: '18px',
        cursor: 'pointer',
    },
    dropdown: {
        position: 'absolute',
        top: '30px',
        right: '0',
        width: '150px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        zIndex: 1000,
    },
    dropdownItem: {
        padding: '10px 15px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee',
        color: '#333',
    },
    dropdownItemLast: {
        borderBottom: 'none',
    },
};
