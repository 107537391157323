import React, { useEffect, useState, useRef } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { OverlayTrigger, Tooltip, Table, Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { BiSolidVideos } from 'react-icons/bi'
import HelpTour from "../../components/HelpTour.tsx";
import PaginationComponent from "./PaginationComponent.js";

const TableByApprenticeship = ({steps, joyrideStatus, setJoyrideStatus}) => {
  const { t } = useTranslation()
  const clearAllRef = useRef(null);
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const [data, setData] = useState([]);
  const [resultData, setResultData] = useState([])
  const [studyField, setStudyField] = useState('')
  const [selectedProvince, setSelectedProvince] = useState('')
  const [showPaginationComponent, setShowPaginationComponent] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false);
  const [helpRun, setHelpRun] = useState(false)
  const [joyrideSteps, setSteps] = useState([])

  const handlePaginationData = (studyFieldId, provinceId) => {
    setStudyField(studyFieldId)
    setSelectedProvince(provinceId)
    setShowPaginationComponent(true)
  }

  const handlePaginationClearAll = () => {
    clearAllRef.current?.();
  }

  const resetStepStates = () => {
    setStepStates(steps?.map(() => ({ showVideo: false })) || [])
  }

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type } = value;

    if (action === 'reset' || action === 'close') {
      setHelpRun(false)
      setJoyrideStatus(false)
      resetStepStates()
    }
  }

  useEffect(() => {
    if (joyrideStatus) {
      let joyrideSteps; // Initialize a variable to hold steps
      if (showPaginationComponent) {
        // Use slice to avoid mutating joyrideSteps
        joyrideSteps = steps?.slice(-2)?.reverse() // Get steps from index 2 onward
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content).split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            // Ensure to set the proper buttons
            disableCloseOnEsc: true, // Disable close on Esc if needed
            disableBeacon: true,
            showSkipButton: false, // Optionally hide the skip button
            showNextButton: true, // Show next button
            closeButtonText: "Close", // Change button text if needed
            placement: "top"
          }
        });
      }
      setSteps(joyrideSteps); // Set the steps state
      setHelpRun(true); // Set help run state
    }
  }, [joyrideStatus, stepStates]);

  useEffect(() => {
    let url = API_URLS.eductaionalProgramByApprenticeship;

    httpClient({
      method: "get",
      url,
    }).then(({ result, error }) => {
      if (result) {
        let resultantArr = [];
        let iterableArr = result.counts;
        for (let i of iterableArr) {
          let obj1 = {};
          obj1["title"] = capitalizeFirstLetter(t(i.fieldOfLanguageKey));
          obj1["id"] = i.fieldOfStudyId
          obj1["rowSpan"] = i.geographies.length;
          for (let g in i.geographies) {
            let obj2 = {};
            obj2["id"] = i.fieldOfStudyId
            obj2["province"] = capitalizeFirstLetter(
              t(i.geographies[g].geographyLanguageKey)
            );
            obj2["provinceId"] = i.geographies[g].geographyId
            obj2["count"] = i.geographies[g].count;
            resultantArr.push(Number(g) === 0 ? { ...obj1, ...obj2 } : obj2);
          }
        }
        setResultData(result.counts.map(({ fieldOfStudyId, ...rest }) => ({
          id: fieldOfStudyId, // Rename fieldOfStudyId to id
          ...rest // Include the rest of the properties unchanged
        })));
        setData(resultantArr);
      }
    });
  }, [t]);

  return (
    <>
    <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
      // setStepIndex={handlePaginationHelpTour}
      />
      {showPaginationComponent ? (
        <>
          <div className="row gx-0 mb-3">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <button
                onClick={() => setShowPaginationComponent(!showPaginationComponent)}
                className="btn btn-link text-theme font-small ps-2 m-0"
              >
                {t("educationalProgram.tabApprenticeOffered.paginationComponent.backButtonTable")}
              </button>
              <button
                className="clear_btn"
                onClick={handlePaginationClearAll}
                style={{ fontSize: "15px", marginRight: "10px" }}
              >
                {t("commonSubHeadingClearAll")}
              </button>
            </div>
          </div>
          <PaginationComponent
            item={studyField}
            apprenticeship={true}
            province={selectedProvince}
            clearAllRef={clearAllRef}
          />
        </>
      ) : (
        <>
          <h6 className="py-3 px-2">{t("educationalProgram.tabApprenticeOffered.title")}</h6>
          <div className="row gy-3">
            {resultData &&
              resultData.map((data, index) => {
                // Create a new object with 'id' instead of 'fieldOfStudyId'
                const { fieldOfStudyId: id, ...rest } = data;
                return (
                  <div className="col-12 col-md-12 col-lg-4" key={id}>
                    <Card className="custom-card h-100">
                      <h3 className="custom-card-title">{capitalizeFirstLetter(t(data.fieldOfLanguageKey))}</h3>
                      
                      <div className="table-container">
                        <div className="table-header">
                          <table className="custom-card-table">
                            <thead>
                              <tr>
                                <th>{t("educationalProgram.tabApprenticeOffered.tableColumnHeading.ProvinceTerritory")}</th>
                                <th>{t("educationalProgram.tabApprenticeOffered.tableColumnHeading.Programs")}</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="table-body" style={{ overflowX: 'auto' }}>
                          <table className="custom-card-table">
                            <tbody>
                              {data.geographies &&
                                data.geographies.map((tableData, index1) => (
                                  <OverlayTrigger
                                    key={index1}
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        {t("clickForProgramDetails")}
                                      </Tooltip>
                                    )}
                                    // placement={index % 2 === 0 ? 'right' : 'left'}
                                    placement={'top'}
                                  >
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handlePaginationData(data, tableData.geographyId)}
                                    >
                                      <td>{capitalizeFirstLetter(t(tableData.geographyLanguageKey))}</td>
                                      <td>{tableData.count}</td>
                                    </tr>
                                  </OverlayTrigger>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );  
};

export default TableByApprenticeship;
