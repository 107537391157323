import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TableViewEducationalProgram from "./EducationalProgramTabComponents/TableViewEducationalProgram";
import HorizontalChartEducationalProgram from "./EducationalProgramTabComponents/HorizontalChartEducationalProgram";
import TableByInstitution from "./EducationalProgramTabComponents/TableByInstitution";
import TableByApprenticeship from "./EducationalProgramTabComponents/TableByApprenticeship";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Error403 from "../components/Error403.jsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext"
import { Loader } from "./Loader.tsx";
import { useNavigate } from "react-router-dom";

const TabComponent = ({
  colData,
  countByGeography,
  areaOfStudy,
  programCountByAreaOfStudy,
  getTabData,
  joyrideStatus,
  setJoyrideStatus
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const navigate = useNavigate()
  const { permissions, joyrideSteps, userPlan, user } = useUser()
  const isSuperAdmin = user?.role === "SUPER_ADMIN"
  const [overflowStyle, setOverflowStyle] = useState("initial");
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(
    JSON.parse(localStorage.getItem("user")).settings.educationalPrograms.tab ||
    "tab1"
  ); // State to manage active tab

  const handleTab = (k) => {
    setKey(k);
    getTabData("tabcomponent", k);
  };

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);  

  useEffect(() => {
      setKey("tab1");
  }, [userPlan]);

  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={(k) => handleTab(k)}
        className="custom_tab"
      >
        {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabProvince'))) && ( */}
          <Tab
            eventKey="tab1"
            title={t("educationalProgram.tabTitle.provinceAndTerritory")}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAreaOfStudy'))) && ( */}
          <Tab
            eventKey="tab2"
            title={t("educationalProgram.tabTitle.areaOfStudy")}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabLevelOfInstruction'))) && ( */}
          <Tab
            eventKey="tab3"
            title={t("educationalProgram.tabTitle.levelOfInstruction")}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabAppenticeships'))) && ( */}
          <Tab
            eventKey="tab4"
            title={t("educationalProgram.tabTitle.apprenticeshipOffered")}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('educationalProgramInsights') && permissions.includes('educationTabComparison'))) && ( */}
        {(isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.dataComparison == "accessible")) && (
          <Tab
            eventKey="tab5"
            title={t("commonTitleComparison")}
            className="tab_container mt-2"
          />
        )}
      </Tabs>
      </div>

<div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)"}}>
        {key === "tab1" && (
          <TableViewEducationalProgram
            colData={colData}
            countByGeography={countByGeography}
            tabSettings={getTabData}
            joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}
            steps={joyrideSteps?.educationalProgramInsights?.tabProvinceTerritory}
          />
        )}
        {key === "tab2" && (
          <HorizontalChartEducationalProgram steps={joyrideSteps?.educationalProgramInsights?.tabAreaOfStudy} joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}/>
        )}
        {key === "tab3" && (
          <TableByInstitution steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
            setJoyrideStatus= {setJoyrideStatus}/>
        )}
        {key === "tab4" && (
          <TableByApprenticeship steps={joyrideSteps?.educationalProgramInsights?.tabLevelOfInstruction} joyrideStatus={joyrideStatus}
          setJoyrideStatus= {setJoyrideStatus}/>
        )}
        {key === "tab5" && (isSuperAdmin || userPlan?.subscriptionPlanId?.features?.educationalProgramInsights?.dataComparison == "accessible") && (
          <Comparison field={"educationalInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
        )}
      </div>
    </div>
  );
};

export default TabComponent;

