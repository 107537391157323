import React, { useState } from 'react'
import Payment from './StripeForms'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51QfgosAC6MN0hm6W81Omk3Db1GX5X6M1SCJ8l9CKkqmADFjkiSJnSKlmFxNy6Qj9EtbZEfRX88pJvjm5ZfX8f1Rh00wuADDXmt');

const Checkout = () => {
    const location = useLocation();
  const { clientSecret } = location.state || {};
// const [clientSecret, setClientSecret] = useState()
    const options = {
        // passing the client secret obtained in step 3
        clientSecret
        // Fully customizable with appearance API.
        // appearance: {/*...*/},
      };
  return (
    <Elements stripe={stripePromise} options={options}>
        <Payment clientSecret={clientSecret}/>
    </Elements>
  )
}

export default Checkout