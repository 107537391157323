import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import logoblack from "../../../assets/fpsc-logo.png";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/GeoIcon.svg";
import { ReactComponent as SectorIcon } from "../../../assets/icons/SectorIcon.svg";
import { ReactComponent as EducationIcon } from "../../../assets/icons/EducationIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/DocumentIcon.svg";
import { ReactComponent as GearIcon } from "../../../assets/icons/GearIcon.svg";
import LanguageSelector from "../../../components/LanguageSelector";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/LogoutIcon.svg";
import { Link } from "react-router-dom";
// import { useIntercom } from "react-use-intercom";
import { useUser } from '../../../context/UserContext';
import { FiMenu } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { RiAccountCircleLine } from "react-icons/ri";

const Sidebar = () => {
  const { permissions, logout, user, userPlan } = useUser();
  
  const navigate = useNavigate();
  const location = useLocation();
  // const { shutdown } = useIntercom();
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Open by default on desktop
  const [decodedToken, setDecodedToken] = useState({})
  const [plan, setPlan] = useState()

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    logout();
    // shutdown();
  };

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      setDecodedToken(jwtDecode(token))
    } else {
      navigate('/')
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true); // Ensure sidebar is open on desktop view
      } else {
        setIsSidebarOpen(false); // Close sidebar initially on mobile
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // This will ensure the component updates if userPlan changes
    if (userPlan) {
      setPlan(userPlan);
    }
  }, [userPlan]);

  return (
    <div
      className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <a href="/" className="text-decoration-none sidebar_logo">
        <img src={logoblack} alt="logo" style={{ width: "154px" }} />
      </a>

      {/* Hamburger icon for mobile view */}
      {isMobile && (
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="menu-toggle-button"
          aria-label="Toggle sidebar"
          style={{ background: "none", border: "none", cursor: "pointer", margin: "10px" }}
        >
          {isSidebarOpen ? <IoCloseCircleOutline size={24} /> : <FiMenu size={24} />}
        </button>
      )}

      {/* Sidebar links */}
      {(!isMobile || isSidebarOpen) && (
        <div className={`nav-bar ${isMobile ? 'show' : ''}`} style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

          <div>
            <ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item"><LanguageSelector /></li>
              {user?.role === "SUPER_ADMIN" &&
                (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/establishment-data"
                        className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <GlobeIcon className="icon" />
                        {t("sidebarEstablishmentInsights")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/educational-program"
                        className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <EducationIcon className="icon" />
                        {t('sidebarEducationalProgramsInsights')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/employment-data"
                        className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <SectorIcon className="icon" />
                        {t("sidebarWorkforceInsights")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/documents"
                        className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <DocumentIcon className="icon" />
                        {t('sidebarDocuments')}
                      </Link>
                    </li>
                  </>
                )
              }
              {user.role == "USER" && userPlan && 
                (
                  <>
                    <li className="nav-item">
                      <Link
                        to="/establishment-data"
                        className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <GlobeIcon className="icon" />
                        {t("sidebarEstablishmentInsights")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/educational-program"
                        className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <EducationIcon className="icon" />
                        {t('sidebarEducationalProgramsInsights')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/employment-data"
                        className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <SectorIcon className="icon" />
                        {t("sidebarWorkforceInsights")}
                      </Link>
                    </li>
                    {/* <li>
                  <Link
                    to="/documents"
                    className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <DocumentIcon className="icon" />
                    {t('sidebarDocuments')}
                  </Link>
                </li> */}
                  </>
                )
              }
              {/* {(permissions?.includes('establishmentInsights') || permissions?.includes('full')) && (
                <li className="nav-item">
                  <Link
                    to="/establishment-data"
                    className={`nav-link ${location.pathname === "/establishment-data" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <GlobeIcon className="icon" />
                    {t("sidebarEstablishmentInsights")}
                  </Link>
                </li>
              )}
              {(permissions?.includes('educationalProgramInsights') || permissions?.includes('full')) && (
                <li>
                  <Link
                    to="/educational-program"
                    className={`nav-link ${location.pathname === "/educational-program" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <EducationIcon className="icon" />
                    {t('sidebarEducationalProgramsInsights')}
                  </Link>
                </li>
              )}
              {(permissions?.includes('workforceInsights') || permissions?.includes('full')) && (
                <li>
                  <Link
                    to="/employment-data"
                    className={`nav-link ${location.pathname === "/employment-data" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <SectorIcon className="icon" />
                    {t("sidebarWorkforceInsights")}
                  </Link>
                </li>
              )}
              {decodedToken && (decodedToken as any)?.role?.role === "SUPER_ADMIN" && (
                <li>
                  <Link
                    to="/documents"
                    className={`nav-link ${location.pathname === "/documents" ? "active" : ""}`}
                    aria-current="page"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  >
                    <DocumentIcon className="icon" />
                    {t('sidebarDocuments')}
                  </Link>
                </li>
              )} */}
            </ul>
          </div>

          <div className="sidebar-footer" style={{ marginBottom: "30px" }}>
            <ul className="nav nav-pills flex-column mb-0">
              {/* <li className="nav-item">
                <Link
                  to="/subscription-plans"
                  className={`nav-link ${location.pathname === "/subscription-plans" ? "active" : "link-dark"}`}
                  aria-current="page"
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                >
                  <GearIcon className="icon" />
                  {'Subscription Plans'}
                </Link>
              </li> */}
              {user?.role === "SUPER_ADMIN" ?
                (
                  <>
                     {/* < li className="nav-item">
                      <Link
                        to="/account-info"
                        className={`nav-link`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <RiAccountCircleLine
                          fontSize={20}
                          // className={`nav-link ${location.pathname === "/account-info" ? "active" : "link-dark"}`}
                          fill={location.pathname === "/account-info" ? "red" : "gray"}
                        // style={{
                        //   color: location.pathname === "/account-info" ? "red" : "gray", // Change color dynamically
                        //   // transition: "color 0.3s ease", // Smooth transition
                        // }}
                        />
                        {'Account'}
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/settings"
                        className={`nav-link ${location.pathname === "/settings" ? "active" : "link-dark"}`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <GearIcon className="icon" />
                        {t('sidebarSettings')}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        role="button"
                        tabIndex={0}
                        onClick={handleLogout}
                      >
                        <LogoutIcon className="icon" />
                        {t('sidebarLogout')}
                      </a>
                    </li>
                  </>
                )
                :
                (
                  <>
                    < li className="nav-item">
                      <Link
                        to="/account-info"
                        className={`nav-link`}
                        aria-current="page"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      >
                        <RiAccountCircleLine
                          fontSize={20}
                          // className={`nav-link ${location.pathname === "/account-info" ? "active" : "link-dark"}`}
                          fill={location.pathname === "/account-info" ? "red" : "gray"}
                        // style={{
                        //   color: location.pathname === "/account-info" ? "red" : "gray", // Change color dynamically
                        //   // transition: "color 0.3s ease", // Smooth transition
                        // }}
                        />
                        {'Account'}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        role="button"
                        tabIndex={0}
                        onClick={handleLogout}
                      >
                        <LogoutIcon className="icon" />
                        {t('sidebarLogout')}
                      </a>
                    </li>
                  </>
                )
              }
            </ul>
          </div>
        </div>
      )
      }
    </div >
  );
};

export default Sidebar;