import {
  NAICS3111,
  NAICS3112,
  NAICS3113,
  NAICS3114,
  NAICS3115,
  NAICS3116,
  NAICS3117,
  NAICS3118,
  NAICS3119,
  NAICS3121,
  NAICS3123,
} from "./images";

export const IconSetName = {
  NAICS3111: NAICS3111(),
  NAICS3112: NAICS3112(),
  NAICS3113: NAICS3113(),
  NAICS3114: NAICS3114(),
  NAICS3115: NAICS3115(),
  NAICS3116: NAICS3116(),
  NAICS3117: NAICS3117(),
  NAICS3118: NAICS3118(),
  NAICS3119: NAICS3119(),
  NAICS3121: NAICS3121(),
  NAICS3123: NAICS3123(),
};

export const TableTitleForEducationProgram = [
  { name: "alberta", code: "AB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.AB" },
  { name: "british columbia", code: "BC", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.BC" },
  { name: "manitoba", code: "MB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.MB" },
  { name: "new brunswick", code: "NB", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NB" },
  { name: "newfoundland and labrador", code: "NFL", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NFL" },
  // { name: "northwest territories", code: "NT", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NWT" },
  { name: "nova scotia", code: "NS", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NS" },
  { name: "nunavut", code: "NU", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.NU" },
  { name: "ontario", code: "ON", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.ON" },
  { name: "prince edward island", code: "PE", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.PE" },
  { name: "quebec", code: "QC", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.QC" },
  { name: "saskatchewan", code: "SK", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.SK" },
  { name: "yukon", code: "YU", languageKey: "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.YU" },
];

export const ColorsForSector = {
  3111: "#de9b61",
  3112: "#fbb518",
  3113: "#d460a2",
  3114: "#ef4b32",
  3115: "#27ade3",
  3116: "#e91c4d",
  3117: "#113f70",
  3118: "#663300",
  3119: "#119448",
  3121: "#804795",
  3123: "#0f5b2e",
};

export const PaginationTableColumns = {
  labels: ['Institution Name', 'Program Name', 'Duration', 'Language Of Instruction', 'Level', 'City', 'Province', "Urls"],
  languageKeys: ['paginationTable.column.institutionName', 'paginationTable.column.programName', 'paginationTable.column.duration', 'paginationTable.column.languageOfInstruction', 'paginationTable.column.level', 'paginationTable.column.city', 'paginationTable.column.province', 'paginationTable.column.urls'],
  fields: ['institutionName', 'programName', 'duration', 'languageOfInstruction', 'level', 'city', 'province', "urls"]
};

export const educationFields = [
  { name: "Province and Territory", value: "provinceTerritory", languageKey: "comparisonInputValue.provinceTerritory" },
  { name: "Level of Instruction", value: "levelInstruction", languageKey: "comparisonInputValue.levelInstruction" },
  { name: "Apprenticeships", value: "apprenticeOffered", languageKey: "comparisonInputValue.apprenticeships" },
]

export const employmentFields = [
  { name: "Province and Territory", value: "provinceTerritory", languageKey: "comparisonInputValue.provinceTerritory" },
  { name: "Sector", value: "sector", languageKey: "comparisonInputValue.sector" },
  { name: "Year", value: "year", languageKey: "comparisonInputValue.year" },
  { name: "Breakdown", value: "breakdown", languageKey: "comparisonInputValue.breakdown" }
]

export const permissionOptions = [
  {
    label: "settings.tabUsersTypes.label.sidebarNavigation",
    options: [
      { value: 'establishmentInsights', label: 'establishmentInsights' },
      { value: 'educationalProgramInsights', label: 'educationalProgramInsights' },
      { value: 'workforceInsights', label: 'workforceInsight' }
    ]
  },
  {
    label: "settings.tabUsersTypes.label.establishmentInsights",
    options: [
      { value: 'establishmentTabProvince', label: 'establishmentTabProvince' },
      { value: 'establishmentTabSector', label: 'establishmentTabSector' },
      { value: 'establishmentTabComparison', label: 'establishmentTabComparison' }
    ]
  },
  {
    label: "settings.tabUsersTypes.label.educationalProgramInsights",
    options: [
      { value: 'educationTabProvince', label: 'educationTabProvince' },
      { value: 'educationTabAreaOfStudy', label: 'educationTabAreaOfStudy' },
      { value: 'educationTabLevelOfInstruction', label: 'educationTabLevelOfInstruction' },
      { value: 'educationTabAppenticeships', label: 'educationTabAppenticeships' },
      { value: 'educationTabComparison', label: 'educationTabComparison' }
    ]
  },
  {
    label: "settings.tabUsersTypes.label.workforceInsights",
    options: [
      { value: 'workforceTabProvince', label: 'workforceTabProvince' },
      { value: 'workforceTabTotalEmployment', label: 'workforceTabTotalEmployment' },
      { value: 'workforceTabDemographicBreakdown', label: 'workforceTabDemographicBreakdown' },
      { value: 'workforceTabComparison', label: 'workforceTabComparison' }
    ]
  }
];

export const helpStepOptions = [
  {
    name: "Establishment Insights",
    value: "establishmentInsights",
    translationKey: "settings.tabVideoUpload.selectField.inputValue.establishmentInsights",
    tab: [
      {
        name: "Province and Territory",
        value: "tabProvinceTerritory",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.provinceTerritory",
        target: [
          { name: "Selection Dropdown", value: "multiselect-dropdown", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" },
          { name: "Multiselect Establishment", value: "multiselect-establishment", translationKey: "settings.tabVideoUpload.selectStep.inputValue.multiselectEstablishment" },
          { name: "Map", value: "establishment-map", translationKey: "settings.tabVideoUpload.selectStep.inputValue.map" },
          { name: "Map Zoom In", value: "highcharts-zoom-in", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomIn" },
          { name: "Map Zoom Out", value: "highcharts-zoom-out", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomOut" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
      {
        name: "Sector",
        value: "tabSector",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.sector",
        target: [
          { name: "Selection Dropdown", value: "sector_filter_container", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" },
          { name: "Barchart", value: "establishment-barchart", translationKey: "settings.tabVideoUpload.selectStep.inputValue.barChart" },
          { name: "Piechart", value: "establishment-sector-insights_pie", translationKey: "settings.tabVideoUpload.selectStep.inputValue.pieChart" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      }
    ],
  },
  {
    name: "Educational Program Insights",
    value: "educationalProgramInsights",
    translationKey: "settings.tabVideoUpload.selectField.inputValue.educationalProgramInsights",
    tab: [
      {
        name: "Province and Territory",
        value: "tabProvinceTerritory",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.provinceTerritory",
        target: [
          { name: "Map", value: "education-tabProvince-map", translationKey: "settings.tabVideoUpload.selectStep.inputValue.map" },
          { name: "Map Zoom In", value: "highcharts-zoom-in", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomIn" },
          { name: "Map Zoom Out", value: "highcharts-zoom-out", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomOut" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
      {
        name: "Area of Study",
        value: "tabAreaOfStudy",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.areaOfStudy",
        target: [
          { name: "Selection Dropdown", value: "areaOfStudy-dropdown", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
      {
        name: "Level of Instruction",
        value: "tabLevelOfInstruction",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.levelOfInstruction",
        target: [
          { name: "Selection Dropdown", value: "levelOfInstruction-dropdown", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
      {
        name: "Education Program Details",
        value: "pagination",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.educationProgramDetails",
        target: [
          { name: "Selection Dropdown", value: "pagination-filter-dropdown", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" }
        ]
      },

    ],
  },
  {
    name: "Workforce Insights",
    value: "workforceInsights",
    translationKey: "settings.tabVideoUpload.selectField.inputValue.workforceInsights",
    tab: [
      {
        name: "Province and Territory",
        value: "tabProvinceTerritory",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.provinceTerritory",
        target: [
          { name: "Map", value: "workforce-tabProvince-map", translationKey: "settings.tabVideoUpload.selectStep.inputValue.map" },
          { name: "Map Zoom In", value: "highcharts-zoom-in", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomIn" },
          { name: "Map Zoom Out", value: "highcharts-zoom-out", translationKey: "settings.tabVideoUpload.selectStep.inputValue.mapZoomOut" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
    ],
  },
  {
    name: "Comparison",
    value: "comparison",
    translationKey: "settings.tabVideoUpload.selectField.inputValue.comparison",
    tab: [
      {
        name: "Comparison",
        value: "comparison",
        translationKey: "settings.tabVideoUpload.selectTab.inputValue.comparison",
        target: [
          { name: "Selection Dropdown", value: "comparison-filters", translationKey: "settings.tabVideoUpload.selectStep.inputValue.selectionDropdown" },
          { name: "Compare Button", value: "comparsion_button", translationKey: "settings.tabVideoUpload.selectStep.inputValue.compareButton" },
          { name: "Clear All", value: "clear_btn", translationKey: "settings.tabVideoUpload.selectStep.inputValue.clearAll" }
        ]
      },
    ],
  }
]

export const features = {
  "establishmentInsights": {
    "businessSize": "limited",
    "dataYears": "limited",
    "subSectorMultiselect": "inaccessible",
    "barGraphClick": "inaccessible",
    "interactiveGeoMap": "inaccessible",
    "dataComparison": "inaccessible"
  },
  "educationalProgramInsights": {
    "dataComparison": "inaccessible"
  },
  "workforceInsights": {
    "yearlySectorData": "inaccessible",
    "sectors": "limited",
    "demographicSectorDropdown": "limited",
    "demographicAgeRangeDropdown": "inaccessible",
    "dataComparison": "inaccessible"
  }
}

export const keyTextMapping = {
  "businessSize": "Business Size Insights",
  "dataYears": "Data Years",
  "subSectorMultiselect": "Sub Sector Multiselect",
  "barGraphClick": "Bar Graph Click",
  "subSectorInsights": "Sub-Sector Insights",
  "interactiveGeoMap": "Interactive Geo Map",
  "sectorWiseData": "Sector-wise Data",
  "subSectorBreakdownData": "Sub-Sector Breakdown Data",
  "dataComparison": "Data Comparison",
  "yearlySectorData": "Yearly Sector Data",
  "sectors": "Sectors",
  "demographicSectorDropdown": "Demographic Sector Dropdown",
  "demographicAgeRangeDropdown": "Demographic Age Range Dropdown"
};

