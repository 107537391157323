import React, { useState, useEffect } from "react";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "../../constants/apiUrls.ts";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { capitalizeFirstLetter } from "../../services/commonFn.js";
import { features, keyTextMapping } from "../../constants/commonData.js";
import { ToastContainer, toast } from "react-toastify";

const Feature = () => {
  const { t } = useTranslation();

  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [planFeatures, setPlanFeatures] = useState();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const getTextForKey = (key) => keyTextMapping[key] || key;

  const updateFeature = (category, field, newValue) => {
    setPlanFeatures((prevFeatures :any) => ({
      ...prevFeatures,
      [category]: {
        ...prevFeatures[category],
        [field]: newValue,
      },
    }));
  };

  const handleCheckboxChange = (category, field, checked) => {
    const newValue =
      field === "businessSize" ||
      field === "dataYears" ||
      field === "demographicSectorDropdown" ||
      (category === "workforceInsights" && field === "sectors")
        ? checked
          ? "full"
          : "limited"
        : checked
        ? "accessible"
        : "inaccessible";

    updateFeature(category, field, newValue);

    // Trigger backend update immediately after the toggle change
    handleUpdateSubscriptionFeatures({
      ...planFeatures,
      [category]: {
        ...planFeatures[category],
        [field]: newValue,
      },
    });
  };

  const getSubscriptionPlans = () => {
    httpClient({
      method: "get",
      url: API_URLS.getSubscriptionPlans,
    })
      .then(({ result, error }) => {
        if (result.status) {
          setSubscriptionPlans(result.subscriptions);
        } else {
          setSubscriptionPlans([]);
        }
        if (error) {
          return;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleUpdateSubscriptionFeatures = (updatedFeatures) => {
    const data = {
      planId: subscriptionPlan,
      features: updatedFeatures,
    };

    httpClient({
      method: "post",
      url: API_URLS.updateSubscriptionFeatures,
      data,
    })
      .then(({ result, error }) => {
        if (result.status) {
          toast.success(result.message);
        }
        if (error) {
          toast.error(error.message);
          return;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  return (
    <div className="container mt-4">
      <ToastContainer />
      <h1 className="m-0">Features</h1>

      <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
        <Form.Group controlId="formSubscriptionPlan" className="mb-3">
          <Form.Label>Select Subscription Plan</Form.Label>
          <Form.Control
            as="select"
            value={subscriptionPlan ? JSON.stringify(subscriptionPlan) : ""}
            onChange={(e) => {
              
              setSubscriptionPlan(JSON.parse(e.target.value));
              setPlanFeatures(JSON.parse(e.target.value).features);
            }}
            className="form-select c-form-group"
            style={{ width: "350px" }}
          >
            <option value="">Select Plan</option>
            {subscriptionPlans.map((item :any, index) => (
              <option key={index} value={JSON.stringify(item)}>
                {item.plan}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      <div className="feature-cards">
        {subscriptionPlan &&
          Object.entries(planFeatures as any).map(([category, fields]) => (
            <Card key={category} className="mb-3">
              <Card.Body>
                <Card.Title className="mb-5">
                  {capitalizeFirstLetter(category.replace(/([A-Z])/g, " $1"))}
                </Card.Title>
                {Object.entries(fields as any).map(([field, value]) => (
                  <div className="toggle-container mb-3" key={`${category}-${field}`}>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        id={`${category}-${field}`}
                        checked={value === "full" || value === "accessible"}
                        onChange={(e) =>
                          handleCheckboxChange(category, field, e.target.checked)
                        }
                      />
                      <span className="slider"></span>
                    </label>
                    <span className="toggle-label" style={{ marginLeft: "20px" }}>
                      {getTextForKey(field)}
                    </span>
                  </div>
                ))}
              </Card.Body>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default Feature;
