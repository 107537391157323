import React from 'react'
import AccountInfo from './AccountInfo.tsx'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51QfgosAC6MN0hm6W81Omk3Db1GX5X6M1SCJ8l9CKkqmADFjkiSJnSKlmFxNy6Qj9EtbZEfRX88pJvjm5ZfX8f1Rh00wuADDXmt');

const Account = () => {
    return (
         <Elements stripe={stripePromise}>
            <AccountInfo />
        </Elements>
    )
}

export default Account