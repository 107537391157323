module.exports = {
  NAICS3111: () => require("../assets/sector-icons/New folder/NAICS 3111.png"),
  NAICS3112: () => require("../assets/sector-icons/New folder/NAICS 3112.png"),
  NAICS3113: () => require("../assets/sector-icons/New folder/NAICS 3113.png"),
  NAICS3114: () => require("../assets/sector-icons/New folder/NAICS 3114.png"),
  NAICS3115: () => require("../assets/sector-icons/New folder/NAICS 3115.png"),
  NAICS3116: () => require("../assets/sector-icons/New folder/NAICS 3116.png"),
  NAICS3117: () => require("../assets/sector-icons/New folder/NAICS 3117.png"),
  NAICS3118: () => require("../assets/sector-icons/New folder/NAICS 3118.png"),
  NAICS3119: () => require("../assets/sector-icons/New folder/NAICS 3119.png"),
  NAICS3121: () => require("../assets/sector-icons/New folder/NAICS 3121.png"),
  NAICS3123: () => require("../assets/sector-icons/New folder/NAICS 3123.png"),
};
