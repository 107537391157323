import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import AddUser from "../pages/settings/AddUser.tsx";
import ChangePassword from "../pages/settings/ChangePassword.tsx";
import { useTranslation } from "react-i18next";
import TableSetting from "../pages/settings/TableSetting.tsx";
import TranslationManager from "./TranslationManager.jsx";
import UserRole from "../pages/settings/UserType.tsx"
import StepHelpUpload from "./StepVideo.tsx";
import FeaturePage from "../pages/settings/Feature.tsx";
import SubscriptionManagement from "../pages/settings/SubscriptionManagement.tsx";

const TabComponent = () => {
  const { t } = useTranslation()
  const decodedToken = jwtDecode(localStorage.getItem("token"));
  const tab = decodedToken?.role.role === 'SUPER_ADMIN' ? 'tab1' : 'tab3'
  const [key, setKey] = useState(tab); // State to manage active tab

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
        <Tabs className="custom_tab" id="controlled-tab" activeKey={key} onSelect={(k) => setKey(k)}>
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab1" title={t('settings.tabTitle.users')} className="tab_container mt-2" />
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab2" title={t('settings.tabTitle.userRolePermission')} className="tab_container mt-2"/>
             
          )}
          <Tab eventKey="tab3" title={t('settings.tabTitle.changePassword')} className="tab_container mt-2"/>
           
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab4" title={t("settings.tabTitle.tableSettings")} className="tab_container mt-2"/>
              
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab5" title={t("settings.tabTitle.translation")} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab6" title={t("settings.tabTitle.helpVideoUpload")} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab7" title={"Feature"} className="tab_container mt-2"/>
          )}
          {decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <Tab eventKey="tab8" title={"Subscription"} className="tab_container mt-2"/>
          )}
        </Tabs>
      </div>
      <div className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>
        {key === "tab1" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <AddUser />
        )}
        {key === "tab2" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
            <UserRole />
        )}
        {key === "tab3" && (<ChangePassword />)}
        
        {key === "tab4" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && (
          <TableSetting />
        )}
        {key === "tab5" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <TranslationManager />
        )}
        {key === "tab6" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <StepHelpUpload />
        )}
        {key === "tab7" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <FeaturePage />
        )}
         {key === "tab8" && decodedToken && decodedToken?.role.role === "SUPER_ADMIN" && ( 
            <SubscriptionManagement />
        )}
      </div>
    </div>
  );
};

export default TabComponent;
