import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import httpClient from "../../services/httpClient.ts";
import API_URLS from "./../../constants/apiUrls.ts";
import CommonHeader from "../../components/Header.tsx";
import { FaPlus } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { permissionOptions } from '../../constants/commonData.js';
import { Modal, Form, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../context/UserContext.js"

const PermissionsSelect = () => {
    const { t } = useTranslation()
    const { updateUserPermissions } = useUser()
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [userTypes, setUserTypes] = useState([])
    const [selectedRole, setSelectedRole] = useState("")
    const [showModal, setShowModal] = useState(false)

    const translatedPermissionOptions = permissionOptions.map(group => ({
        ...group,
        label: t(group.label),
        options: group.options.map(option => ({
            ...option,
            label: t(option.label)  // Translate the label
        }))
    }));

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({ mode: "all" });

    const openModal = () => {
        setShowModal(true);
    };

    function removeUnderscores(str) {
        if (!str || str.length === 0) {
            return ''; // Return empty string if input is empty or invalid
        }

        if (str?.includes('_')) {
            // Replace underscores with spaces
            str = str.replace(/_/g, ' ');
        }

        // Capitalize the first letter of each word and make other letters lowercase
        return str
            .split(' ') // Split string by spaces
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join words back together with spaces
    }

    // Handler to update state with selected values
    const handlePermissionChange = (selectedOptions) => {
        setSelectedPermissions(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleRole = (e) => {
        setSelectedRole(e.target.value)
        const userType = userTypes.filter(item => item._id === e.target.value)
        setSelectedPermissions(userType?.[0]?.permissions?.map(option => option))
    }

    const handleAddUserType = (data) => {
        httpClient({
            method: "post",
            url: API_URLS.addNewUserType,
            data
        })
            .then(({ result, error }) => {
                if (result) {
                    setUserTypes(result?.userTypeList);
                    setShowModal(false);
                    reset();
                    toast.success(result?.message);
                } else {
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const handleUpdatePermissions = () => {
        const data = {
            id: selectedRole,
            permissions: selectedPermissions
        }
        httpClient({
            method: "post",
            url: API_URLS.updateUserPermissions,
            data
        })
            .then(({ result, error }) => {
                if (result) {
                    setSelectedPermissions(result?.response?.permissions)
                    toast.success(result?.message);
                    updateUserPermissions()
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const fetchUserTypes = async () => {
        httpClient({
            method: "get",
            url: API_URLS.getUserTypes,
        })
            .then(({ result, error }) => {
                if (result) {
                    const userRoles = result?.userTypes
                    setUserTypes(userRoles);
                } else {
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    useEffect(() => {
        fetchUserTypes()
    }, [])

    return (
        <div style={{ height: '450px' }}>
            <ToastContainer />
            <div className="pt-2 ps-2 pe-2 ms-3">
                <CommonHeader
                    title={t('settings.tabUsersTypes.header.title')}
                    component={"userRolePermission"}
                    ButtonIcon={<FaPlus />}
                    buttonTitle={t('settings.tabUsersTypes.buttonTitle.addUserType')}
                    buttonEventTriggered={openModal}
                />
            </div>

            <div className="row mx-3">

                <div className="custom-form-group col-md-6 col-12 mb-3">
                    <label>{t('settings.tabUsersTypes.label.selectRole')} *</label>
                    <select
                        value={selectedRole}
                        onChange={handleRole}
                        className="form-select c-form-control fw-semibold c-form-control-sm c-form-control-bg"
                    >
                        <option value="">{t('settings.tabUsersTypes.inputValue.selectRole')}</option>
                        {userTypes?.map((userType) => (
                            <option key={userType?._id} value={userType?._id}>
                                {removeUnderscores(userType?.role)}
                            </option>
                        ))}
                    </select>
                </div>


                <div className="custom-form-group col-md-6 col-12 mb-3">
                    <label>{t('settings.tabUsersTypes.label.selectPermissions')} *</label>
                    <Select
                        isMulti
                        value={permissionOptions
                            .flatMap((group) =>
                                group.options.map((option) => ({
                                    ...option,
                                    label: t(option.label),
                                    isSelected: selectedPermissions.includes(option.value), // Check if option is selected
                                }))
                            )
                            .filter((option) => option.isSelected)}
                        isDisabled={!selectedRole}
                        options={translatedPermissionOptions}
                        onChange={handlePermissionChange}
                        placeholder={t('settings.tabUsersTypes.inputPlaceholder.selectPermissions')}
                    />
                </div>
            </div>


            <div className="row mx-3">
                <div className="col-12 d-flex justify-content-end">
                    <Button
                        variant="primary"
                        style={{ marginBottom: "10px" }}
                        onClick={handleUpdatePermissions}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={() => { setShowModal(false); reset(); }}
                // size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t('settings.tabUsersTypes.modalAddUserRole.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form className="w-100">
                            <div className="custom-form-group">
                                <label>{t('settings.tabUsersTypes.modalAddUserRole.inputTitle.userType')} *</label>
                                <input
                                    type="text"
                                    placeholder={t('settings.tabUsersTypes.modalAddUserRole.inputPlaceholder.userType')}
                                    className="form-select c-form-control  fw-semibold c-form-control-sm c-form-control-bg"
                                    id="InputEmail"
                                    aria-describedby="emailHelp"
                                    {...register("role", {
                                        required: {
                                            value: true,
                                            message: "User Type required",
                                        },
                                        onChange: (e) => {
                                            const capitalizedValue = e.target.value.toUpperCase();
                                            setValue("role", capitalizedValue, { shouldValidate: true });
                                        },
                                    })}
                                />
                                {errors.name && (
                                    <div className="invalid-feedback">{errors.role?.message}</div>
                                )}
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="button button-secondary" variant={null} onClick={handleSubmit(handleAddUserType)}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.save')}</Button>
                    <Button className="button" variant={null} onClick={() => { setShowModal(false); reset() }}>{t('settings.tabUsers.modalAddNewUser.buttonTitle.close')}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PermissionsSelect;