import React, { useState, useRef, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Comparison from "../pages/dashboard/Comparison.tsx";
import Dashboard from "../pages/dashboard/Dashboard.tsx";
import Dashboard2 from "../pages/dashboard/Dashboard2.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from '../context/UserContext';
import { Loader } from "./Loader.tsx";

const TabEstablishment = ({ getTabData, joyrideStatus, setJoyrideStatus }) => {

  const containerRef = useRef(null);
  const { joyrideSteps, user, userPlan } = useUser();
  const { t } = useTranslation();

  const isSuperAdmin = user?.role === "SUPER_ADMIN"
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState();
  const [overflowStyle, setOverflowStyle] = useState("initial");

  const handleTab = (k) => {
    setKey(k);
    getTabData('tabcomponent', k);
  };

  const handleSectorData = (value) => {
    setState(value);
    setKey('tab2');
    getTabData('tabcomponent', 'tab2');
  };

  useEffect(() => {
    setKey('tab1');
    setLoading(false)
  }, []);

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key, state]);

  if (loading) {
    return <Loader show={loading} />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
        <Tabs
          id="controlled-tab"
          activeKey={key}
          onSelect={handleTab}
          className="custom_tab"
        >
          {/* {(isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabProvince'))) && ( */}
          <Tab eventKey="tab1" title={t("workforceInsights.tabTitle.provinceAndTerritory")}
            className="tab_container mt-2"
          />
          {/* )} */}
          {/* {isSuperAdmin || (permissions.includes('establishmentInsights') && permissions.includes('establishmentTabSector'))) && ( */}
          <Tab eventKey="tab2" title={t("workforceInsights.tabTitle.sector")}
            className="tab_container mt-2"
          />
          {/* )} */}
          {(isSuperAdmin ||
            (userPlan?.subscriptionPlanId?.features?.establishmentInsights?.dataComparison === "accessible")) && (
              <Tab
                eventKey="tab3"
                title={t("commonTitleComparison")}
                className="tab_container mt-2"
              />
            )}
        </Tabs>
      </div>


      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>
        {key === "tab1" && (
          <Dashboard handleSectorData={handleSectorData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabProvinceTerritory} />
        )}
        {key === "tab2" && (
          <Dashboard2 state={state} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} steps={joyrideSteps?.establishmentInsights?.tabSector} />
        )}
        {key === "tab3" && (isSuperAdmin || userPlan?.subscriptionPlanId?.features?.establishmentInsights?.dataComparison == 'accessible') && (
          <Comparison field={'establishmentCount'} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} tabSettings={getTabData} />
        )}
      </div>
    </div>
  );
};

export default TabEstablishment;
