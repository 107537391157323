import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [userPlan, setUserPlan] = useState(null)
    const [isContextLoading, setIsContextLoading] = useState(false)
    const [subscriptionPlan, setSubscriptionPlan] = useState()
    const [permissions, setPermissions] = useState([]);
    const [joyrideSteps, setJoyrideSteps] = useState([])
    const [decodedToken, setDecodedToken] = useState();

    const fetchUserPermissions = (role) => {

        httpClient({
            method: "get",
            url: API_URLS.getPermissions.replace("{role}", role),
        })
            .then(({ result, error }) => {

                if (error) {
                    setPermissions([])
                }
                
                if (result) {
                    setPermissions(result?.role?.permissions);
                } 
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }

    const fetchUserPlan = async (id) => {
        try {
            const data = {
                userId: id
            };
            
            const response = await httpClient({
                method: "post",
                url: API_URLS.getUserPlan,
                data
            });
            
            const { result, error } = response;
            
            if (result && result.status) {
                setUserPlan(result.plan);
            } else {
                setUserPlan(null);  // Set the state to null when no active subscription
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    

    const fetchJoyrideSteps = () => {

        httpClient({
            method: "get",
            url: API_URLS.getJoyrideSteps,
        })
            .then(({ result, error }) => {
                if (result) {
                    setJoyrideSteps(result)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }
    
    useEffect(() => {
        const initializeContext = async () => {
            setIsContextLoading(true);
    
            const storedUser = localStorage.getItem('user');
            const storedToken = localStorage.getItem('token');
    
            if (storedUser && storedToken) {
                try {
                    const decodedToken = jwtDecode(storedToken);
                    setDecodedToken(decodedToken);
                    const user = JSON.parse(storedUser);
                    setUser(user);
    
                    // Fetch permissions, plan, and joyride steps in parallel
                    await Promise.all([
                        fetchUserPermissions(user?.role),
                        fetchJoyrideSteps(),
                    ]);
    
                    // Fetch user plan if `user` changes
                    if (user) {
                        await fetchUserPlan(user?._id); // Fetch plan only if user is not null
                    }
                } catch (error) {
                    console.error("Error decoding token:", error);
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    if (window.location.pathname !== '/') {
                        window.location.href = '/';
                    }
                }
            } else {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                if (window.location.pathname !== '/') {
                    window.location.href = '/';
                }
            }
    
            setIsContextLoading(false); // Set loading state to false when all operations are done
        };
    
        initializeContext();
    }, []);
    

    const updateUserPermissions = () => {
        fetchUserPermissions(user.role)
    }

    const checkUserPlan = () => {
        fetchUserPlan()
    }

    const updateUserPlan = (plan) => {
        setUserPlan(plan)
    }

    const login = (userData, token, plan) => {
        setUser(userData);
        setUserPlan(plan)
        // fetchUserPermissions(userData.role)
        fetchJoyrideSteps()
    };

    const logout = () => {
        setUser(null);
        setPermissions([]);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
    };

    return (
        <UserContext.Provider value={{ isContextLoading, user, userPlan, subscriptionPlan, permissions, decodedToken, joyrideSteps, checkUserPlan, updateUserPermissions, fetchJoyrideSteps, login, logout, updateUserPlan }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
