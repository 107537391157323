import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const ConfirmationDialog = ({ message, style = {}, subMessage = '', confirmEventTriggered = '', show, handleClose }) => {


    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false} style={style}
            >
                <Modal.Header >
                {/* closeButton */}
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                    {subMessage && <p>{subMessage}</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmEventTriggered}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default ConfirmationDialog;