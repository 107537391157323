import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, Button, Form } from 'react-bootstrap';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TranslationManager = () => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [translationFileInfo, setTranslationFileInfo] = useState(null);
    const [downloadType, setDownloadType] = useState('');
    const [fileId, setFileId] = useState('');

    const handleDowloadType = (e) => {
        const { value } = e.target;
        setDownloadType(value);
        setFileId(value ? '' : translationFileInfo?._id);
    };

    const handleDownload = async () => {
        let url = API_URLS.downloadFile.replace(":fileId", fileId || null).replace("{type}", downloadType);
        httpClient({
            method: "get",
            url,
            responseType: "blob",
        }).then(({ result }) => {
            if (result) {
                const blob = new Blob([result], { type: result.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                let fileName;
            if (fileId) {
                fileName = "Main Translation Template.csv";
            } else {
                switch (downloadType) {
                    case "province":
                        fileName = "Province Translation Template.csv";
                        break;
                    case "helpAndInformation":
                        fileName = "Help Information Translation Template.csv";
                        break;
                    default:
                        fileName = "Default Template.csv";
                }
            }
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        });
    };

    const uploadFile = () => {
        const url = API_URLS.uploadTranslationFile;
        let formData = new FormData();
        formData.append("file", file);

        httpClient({
            method: "post",
            url,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(({ result }) => {
            if (result) {
                setFile(null);
                // fileInputRef.current.value = '';
                toast.success(result.message);
                window.location.reload();
            }
        });
    };

    const handleFileChange = (event) => setFile(event.target.files[0]);

    useEffect(() => {
        httpClient({ method: "get", url: API_URLS.getTranslationFileInfo })
            .then(({ result }) => {
                if (result) {
                    setFileId(result?.fileDetails?._id);
                    setTranslationFileInfo(result?.fileDetails);
                }
            });
    }, []);

    return (
        <>
            <ToastContainer />
            <Card style={{ width: '100%', marginTop: "10px" }}>
                <Card.Body>
                    <Row className="d-flex justify-content-between align-items-center mb-3">
                        <Col xs={12} md={6} className="text-md-start mb-2 mb-md-0">
                            <h3 className="mb-1">{t('textUploadTranslationFile')}</h3>
                            <p>{t('textLastUpdatedOn')}: {moment(translationFileInfo?.updatedAt).format("YYYY-MM-DD HH:MM:SS A")}</p>
                        </Col>
                        <Col xs={12} md={6} className="d-flex justify-content-md-end justify-content-center align-items-center gap-2">
                            <h3 className="mb-1">{t('textDownloadTemplate')}</h3>

                            <div className="d-flex flex-md-row align-items-center gap-2">
                                <div className="custom-form-group mb-0 w-100">
                                    <select
                                        className="form-select fw-semibold c-form-control-sm"
                                        value={downloadType}
                                        onChange={handleDowloadType}
                                        style={{ width: "100%" }}
                                    >
                                        <option value="">{t('settings.tabTranslation.inputTitle.mainFile')}</option>
                                        <option value="province">{t('province')}</option>
                                        <option value="helpAndInformation">{t('settings.tabTranslation.inputTitle.helpAndInformation')}</option>
                                    </select>
                                </div>

                                <FaDownload
                                    className="fs-5 ms-2"
                                    onClick={handleDownload}
                                    style={{ cursor: "pointer" }}
                                />
                            </div>

                        </Col>
                    </Row>

                    <Form.Group className="mt-3">
                        <Form.Control
                            type="file"
                            onChange={(e) => {
                                handleFileChange(e);
                                e.target.value = null;
                            }}
                        />
                    </Form.Group>

                    <div className="mt-3">
                        {file && (
                            <>
                                <strong>{t('textSelectedFiles')}:</strong>
                                <ul style={fileListStyle}>
                                    <li style={fileItemStyle}>
                                        <span style={fileIconStyle}>📄</span> {file?.name}
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>

                    <div className="d-flex justify-content-end gap-1 mt-3">
                        <Button variant="secondary" onClick={() => setFile(null)}>
                            {t('translationButtonTitleReset')}
                        </Button>
                        <Button variant="primary" onClick={uploadFile}>{t('translationButtonTitleUploadFile')}</Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

const fileListStyle = {
    listStyleType: 'none',
    paddingLeft: 0,
    marginTop: '10px',
};

const fileItemStyle = {
    background: '#f8f9fa',
    padding: '8px 12px',
    marginBottom: '6px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
};

const fileIconStyle = {
    marginRight: '8px',
};

export default TranslationManager;
