import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../context/UserContext.js";
import { capitalizeFirstLetter } from "../services/commonFn.js";
import { keyTextMapping } from "../constants/commonData.js";
import { Modal, Button } from "react-bootstrap";
import { Loader } from "./Loader.tsx";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
const SubscriptionPlans: React.FC = () => {
  const { user, userPlan, updateUserPlan } = useUser()
  const navigate = useNavigate()
  const tableRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showFeatureList, setShowFeatureList] = useState(false)
  const [plans, setPlans] = useState([])
  const [combinedfeatureList, setCombinedfeatureList] = useState({})
  const [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal] = useState(false)
  const getTextForKey = (key) => keyTextMapping[key] || key;
  const combineSpecificFeaturesToArray = (subscriptionPlans) => {
    const combinedFeatures = {};
    subscriptionPlans.forEach((plan) => {
      const planFeatures = plan.features;
      const targetedCategories = Object.keys(planFeatures);  // Extract categories dynamically
      targetedCategories.forEach((category) => {
        if (planFeatures[category]) {
          Object.entries(planFeatures[category]).forEach(([field, value]) => {
            if (!combinedFeatures[category]) {
              combinedFeatures[category] = {};
            }
            if (!combinedFeatures[category][field]) {
              combinedFeatures[category][field] = [];
            }
            combinedFeatures[category][field].push(value);
          });
        }
      });
    });
    return combinedFeatures;
  };
  const handleAddUserSubscriptionPlan = (planId) => {
    const data = {
      userId: user?._id,
      planId
    }
    httpClient({
      method: "post",
      url: API_URLS.addUserSubscriptionPlan,
      data
    })
      .then(({ result, error }) => {
        if (result.status) {
          // toast.success(result.message)
          setShowSuccessSubscriptionModal(true)
          updateUserPlan(result.plan)
          // window.location.reload()
          // setPlans(result?.subscriptions)
          // const structuredFeatures = combineSpecificFeaturesToArray(result?.subscriptions)
          // setCombinedfeatureList(structuredFeatures)
        } else {
          toast.error(result.message)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
      });
  }
  const getSubscriptionPlans = () => {
    setIsLoading(true)
    httpClient({
      method: "get",
      url: API_URLS.getSubscriptionPlans,
    })
      .then(({ result }) => {
        if (result.status) {
          setPlans(result?.subscriptions)
          const structuredFeatures = combineSpecificFeaturesToArray(result?.subscriptions)
          setCombinedfeatureList(structuredFeatures)
          setIsLoading(false)
        } else {
          toast.error(result.message)
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.message);
      });
  }


  useEffect(() => {
    getSubscriptionPlans()
  }, []);


   useEffect(() => {
      if (showSuccessSubcriptionModal) {
        const timer = setTimeout(() => {
          setShowSuccessSubscriptionModal(false);
        }, 1500);
  
        return () => clearTimeout(timer); // Cleanup the timer on component unmount
      }
    }, [showSuccessSubcriptionModal, setShowSuccessSubscriptionModal]);
  

  return (
    <>
      <ToastContainer />
      <Loader show={isLoading} />
      {plans && plans.length > 0 ?
        <section
          className="plans__container"
          style={{
            overflowY: "scroll",
            height: "calc(100vh - 50px)",
            maxHeight: "100%",
          }}
        >
          <div className="plans">
            <div className="plansHero">
              <h1 className="plansHero__title">Choose Your Plan</h1>
            </div>
            <div className={`planItem__container d-flex justify-content-center`}>
              {plans && plans.length > 0 && plans.map((plan, index) => (
                <>
                  <div className={`planItem planItem--free }`} style={{ width: '400px', height: '450px', position: 'relative' }}>
                    {plan?.isMostPopular && <div className="most-popular" style={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
                      <span className="most-popular-span">Most Popular</span>
                    </div>
                    }
                    <div className="planItem--inner" style={{ marginTop: "30px" }}>
                      <div className="planItem_card">
                        <div className="planItem_card__header">
                          <h2>{capitalizeFirstLetter(plan.plan)}</h2>
                        </div>
                        <div className="price">
                          {(plan.price && typeof plan.price == "number") ? `$${plan.price}` : `Free`}<span>{(plan.price && typeof plan.price == "number") ? `/ ${plan.recurrence}` : ``}</span>
                        </div>
                        {user.role !== "SUPER_ADMIN" &&
                          <button type='submit' className="button button-rounded-full  button-full" onClick={() => handleAddUserSubscriptionPlan(plan?._id)} disabled={userPlan}>
                            {userPlan?.subscriptionPlanId?._id === plan?._id ? 'Subscribed' : 'Get Started'}
                          </button>
                        }
                      </div>
                      <hr />
                      <ul className="featureList">
                        <li>
                          Limited Filter Accessibility
                          <span className="icon"></span>
                        </li>
                        <li>
                          Limited Sector Accessibility <span className="icon"></span>
                        </li>
                        <li>
                          No Interactive Charts <span className="icon"></span>
                        </li>
                        <li>
                          No Sub Sector Filters <span className="icon"></span>
                        </li>
                        <li>
                          No Data Comparison <span className="icon"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="plan-feature-detail">
              <div className="d-flex justify-content-center align-items-center gap-3 mb-4" onClick={() => setShowFeatureList(!showFeatureList)}>
                <h3 className="text-center mb-0">Complete features list</h3>
                <div className="toggle-icon">
                  <span
                    className={`feature-list-icon ${showFeatureList ? "rotate-up" : "rotate-down"}`}
                  >
                    <FaChevronUp fontSize={20} />
                  </span>
                </div>
              </div>
              <div className={`table-responsive feature ${showFeatureList ? "show" : "hide"}`}>
                <table className="table align-middle" style={{ tableLayout: 'fixed' }}>
                  <thead>
                    <tr>
                      <th style={{ width: "25%" }}></th>
                      {plans.map((plan, index) => (
                        <th key={index} style={{ width: "25%" }}>{capitalizeFirstLetter(plan?.plan)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      {plans.map((plan, index) => (
                        <td className="text-center">
                          <button type='submit' className="button button-rounded-full button-border-primary" onClick={() => handleAddUserSubscriptionPlan(plan?._id)} disabled={user?.role === "SUPER_ADMIN" || userPlan}>
                          {userPlan?.subscriptionPlanId?._id === plan?._id ? 'Subscribed' : 'Get Started'}
                          </button>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">Establishment Insights</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.establishmentInsights && Object.entries(combinedfeatureList.establishmentInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{capitalizeFirstLetter(getTextForKey(feature))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? 'Limited Access' :
                              status === 'full' ? 'Full Access' :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">Educational Program Insights</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.educationalProgramInsights && Object.entries(combinedfeatureList.educationalProgramInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{capitalizeFirstLetter(feature.replace(/([A-Z])/g, ' $1'))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? 'Limited Access' :
                              status === 'full' ? 'Full Access' :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" height="20"></td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <h3 className="sub-title text-theme">Workforce Insights</h3>
                        <hr />
                      </td>
                    </tr>
                    {combinedfeatureList && combinedfeatureList?.workforceInsights && Object.entries(combinedfeatureList.workforceInsights || {}).map(([feature, statuses], index) => (
                      <tr key={feature}>
                        <td>{capitalizeFirstLetter(feature.replace(/([A-Z])/g, ' $1'))}</td>
                        {statuses.map((status, idx) => (
                          <td key={idx} className="text-center">
                            {status === 'limited' ? 'Limited Access' :
                              status === 'full' ? 'Full Access' :
                                status === 'accessible' ? <span>&#10003;</span> :
                                  status === 'inaccessible' ?
                                    <svg width="20px" height="20px" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" fill="#e61e28" stroke="#e61e28">
                                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.041999999999999996"></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <g fill="none" fill-rule="evenodd" stroke="#e61e28" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                          <circle cx="8.5" cy="8.5" r="8"></circle>
                                          <g transform="matrix(0 1 -1 0 17 0)">
                                            <path d="m5.5 11.5 6-6"></path>
                                            <path d="m5.5 5.5 6 6"></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg> :
                                    <span>&#10005;</span>
                            }
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        :
        <section
        className="plans__container d-flex align-items-center"
          style={{
            overflowY: "scroll",
            height: "calc(100vh - 50px)",
            maxHeight: "100%",
          }}
        >
        <div className="card" style={{ width: "400px", height: "300px", margin: "0 auto", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
  <div className="card-body text-center d-flex align-items-center justify-content-center">
    <p className="m-0" style={{ fontWeight: "600", fontSize: "1.25rem", color: "#e61e28", letterSpacing: "1px" }}>
      No Plans Added
    </p>
  </div>
</div>
        </section>
      }
      <Modal
        show={showSuccessSubcriptionModal}
        onHide={() => { setShowSuccessSubscriptionModal(false); navigate('/establishment-data') }}
        backdrop="static" // Prevent closing on outside click
        keyboard={false} // Prevent closing with "Escape" key
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You have successfully subscribed to our {userPlan?.subscriptionPlanId?.plan} plan.</p>
          {/* <p>With this subscription, you now have access to all the exclusive features and benefits tailored to your needs.</p> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button type="submit" style={{ backgroundColor: "#e61e28" }} onClick={() => { setShowSuccessSubscriptionModal(false); navigate('/establishment-data') }}>
            OK
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default SubscriptionPlans;