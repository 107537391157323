import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TableViewEmploymentData from "./EmploymentDataComponents/TableViewEmploymentData";
import PyramidChart from "./EmploymentDataComponents/PyramidChart";
import LineChart from "./charts/LineChart";
import Comparison from "../pages/dashboard/Comparison.tsx"
import { useTranslation } from "react-i18next";
import { useUser } from "../context/UserContext.js"

const TabForEmployment = ({ getTabData, getTab, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null);
  const { permissions, joyrideSteps, user, userPlan } = useUser()
  const isSuperAdmin = user.role === "SUPER_ADMIN"
  const [loading, setLoading] = useState(true)
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('user'))?.settings?.employment?.tab); // State to manage active tab
  const [overflowStyle, setOverflowStyle] = useState("initial");

  const handleTab = (k) => {
    setKey(k)
    getTabData('tabcomponent', k)
    getTab(k)
  }

  useEffect(() => {
      setKey("tab1");
  }, [userPlan]);

  // Monitor overflow in tab container
  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setOverflowStyle("auto");
    } else {
      setOverflowStyle("initial");
    }
  }, [key]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      {/* Fixed Tabs */}
      <div className="tab-scroll-container">
      <Tabs
        id="controlled-tab"
        activeKey={key}
        onSelect={handleTab}
        className="custom_tab"
      >
        {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabProvince'))) && ( */}
          <Tab eventKey="tab1" title={t('employmentInsights.tabTitle.provinceAndTerritory')}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabTotalEmployment'))) && ( */}
          <Tab eventKey="tab2" title={t('employmentInsights.tabTitle.totalEmploymentOverTime')}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabDemographicBreakdown'))) && ( */}
          <Tab eventKey="tab3" title={t('employmentInsights.tabTitle.breakdown')}
            className="tab_container mt-2"
          />
        {/* )} */}
        {/* {(permissions.includes('full') || (permissions.includes('workforceInsights') && permissions.includes('workforceTabComparison'))) && ( */}
        {(isSuperAdmin || (userPlan && userPlan.subscriptionPlanId && userPlan.subscriptionPlanId.features && userPlan?.subscriptionPlanId?.features?.workforceInsights?.dataComparison == "accessible")) && (
          <Tab eventKey="tab4" title={t('commonTitleComparison')}
            className="tab_container mt-2"
          />
        )}
      </Tabs>
      </div>

      <div ref={containerRef} className='tab_container' style={{ flex: 1, overflowY: "auto", padding: "20px", marginBottom: "10px", maxHeight: "calc(100vh - 100px)" }}>

        {key === "tab1" && (
          <TableViewEmploymentData tabSettings={getTabData} steps={joyrideSteps?.workforceInsights?.tabProvinceTerritory} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />
        )}
        {key === "tab2" && <LineChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
        {key === "tab3" && <PyramidChart tabSettings={getTabData} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
        {key === "tab4" && (isSuperAdmin || (userPlan?.subscriptionPlanId?.features?.workforceInsights?.dataComparison == "accessible")) && <Comparison field={"employmentInsights"} steps={joyrideSteps?.comparison} joyrideStatus={joyrideStatus} setJoyrideStatus={setJoyrideStatus} />}
      </div>
    </div>
  );
};

export default TabForEmployment;
