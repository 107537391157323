import React, { useState, useEffect } from "react";
import { Form, Button, Table, Modal, Card } from "react-bootstrap";
import httpClient from "../../services/httpClient.ts";
import API_URLS from "../../constants/apiUrls.ts";
import { toast, ToastContainer } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { features, keyTextMapping } from "../../constants/commonData.js";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../services/commonFn.js";
import { Loader } from "../../components/Loader.tsx";

const SubscriptionManagement = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const {
    register: registerForm2,
    clearErrors: clearForm2Errors,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    formState: { errors: errorsForm2 },
    setValue
  } = useForm({ mode: "all" });

  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [planFeatures, setPlanFeatures] = useState({})
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState()
  const [availableFeatures, setAvailableFeatures] = useState([
    { id: 1, name: "Feature 1", description: "Description for Feature 1" },
    { id: 2, name: "Feature 2", description: "Description for Feature 2" },
    { id: 3, name: "Feature 3", description: "Description for Feature 3" },
  ]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const handleModalClose = () => setShowModal(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleModalShow = () => setShowModal(true);
  const handleUpdateModalShow = () => setShowUpdateModal(true);
  const getTextForKey = (key) => keyTextMapping[key] || key;

  const updateFeature = (category, field, newValue) => {
    setPlanFeatures((prevFeatures) => ({
      ...prevFeatures,
      [category]: {
        ...prevFeatures[category],
        [field]: newValue,
      },
    }));
  };

  const handleCheckboxChange = (category, field, checked) => {
   
    const newValue =
      field === "businessSize" || field === "dataYears" || field === "demographicSectorDropdown" || (category === "workforceInsights" && field === "sectors")
        ? checked
          ? "full"
          : "limited"
        : checked
          ? "accessible"
          : "inaccessible";

    updateFeature(category, field, newValue);
  };

  const handleUpdateFormData = (plan) => {
    setSelectedSubscriptionPlan(plan)
    setValue("planName", plan.plan)
    setValue("recurrence", plan.recurrence)
    setValue("price", plan.price)
    setValue("isMostPopular", plan.isMostPopular)
  }

  const handleUpdateSubscription = (data) => {
    const updatedData = { ...data, planId: selectedSubscriptionPlan?._id }
    httpClient({
      method: "post",
      url: API_URLS.updateSubscriptionPlan,
      data: updatedData
    })
      .then(({ result, error }) => {

        if (result.status) {
          toast.success("Subscription plan updated successfully");
          reset()
          getSubscriptionPlans()
        } else {
          setAvailableFeatures([])
        }
        if (error) {
          toast.error(error);
          return
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
    setShowUpdateModal(false)
  }

  const handleSaveSubscription = async (data) => {
    const updatedData = { ...data, features: planFeatures }

    httpClient({
      method: "post",
      url: API_URLS.addSubscriptionPlan,
      data: updatedData,
    })
      .then(({ result, error }) => {

        if (result.status) {
          toast.success("Subscription plan added successfully");
          reset()
          // setSubscriptionPlans((prev) => [...prev, result.plan]);
          getSubscriptionPlans()
        }
        if (error) {
          toast.error(error)
          return
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })

    setShowModal(false);
  };

  const handleDeletePlan = async (plan) => {
    const data = {
      planId: plan._id
    }
    httpClient({
      method: "post",
      url: API_URLS.deleteSubscriptionPlan,
      data
    })
      .then(({ result, error }) => {

        if (result.status) {
          toast.success(result.message);
          getSubscriptionPlans()
        } 
        if (error) {
          toast.error(error.message);
          return
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  }

  const getSubscriptionPlans = () => {
    httpClient({
      method: "get",
      url: API_URLS.getSubscriptionPlans,
    })
      .then(({ result, error }) => {

        if (result.status) {
          setSubscriptionPlans(result.subscriptions)

        } else {
          setSubscriptionPlans([])

        }
        if (error) {
          toast.error(error);
          return
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
  }

  useEffect(() => {
    getSubscriptionPlans()
    setPlanFeatures(features)
  }, [])

  return (
    <>
      <ToastContainer />

      <div className="container mt-4">
        <h2>Subscription Management</h2>
        <Button
          variant="primary"
          onClick={handleModalShow}
          className="mb-3"
          style={{ float: "right" }}
        >
          Create Subscription Plan
        </Button>
        <Button
          variant="primary"
          onClick={() => navigate('/subscription-plans')}
          className="mb-3 me-3"
          style={{ float: "right" }}
        >
          Check Subscription Page
        </Button>

        <Table hover>
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Plan Name</th>
              <th>Recurrence</th>
              <th>Price</th>
              <th>Currency</th>
              <th>Actions</th>
              {/* <th>Accessibility Option</th> */}
            </tr>
          </thead>
          <tbody>
            {subscriptionPlans.map((plan, index) => (
              <tr key={plan._id}>
                <td>{index + 1}</td>
                <td>{plan.plan}</td>
                <td>{plan.recurrence}</td>
                <td>{plan.price}</td>
                <td>{plan.currency}</td>
                <td>
                  <FaEdit style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }} title='Edit' onClick={() => { handleUpdateFormData(plan); setShowUpdateModal(true) }} />
                  {/* <RiDeleteBinLine style={{ fontSize: "20px", cursor: "pointer" }} title='Delete' onClick={() => {setSelectedSubscriptionPlan(plan);setShowConfirmationModal(true)}} /> */}
                </td>

                {/* <td> <span dangerouslySetInnerHTML={{ __html: feature.accessibilityOption }} /></td> */}
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal */}
        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Create Subscription Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(handleSaveSubscription)}>
              <Form.Group className="mb-3">
                <Form.Label>Subscription Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter subscription plan name"
                  {...register("planName", { required: "Subscription plan name is required" })}
                  className={errors.planName ? "is-invalid" : ""}
                />
                {errors.planName && (
                  <div className="invalid-feedback">{errors.planName.message?.toString()}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Recurrence</Form.Label>
                <select
                  {...register("recurrence", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {errors.recurrence && (
                  <div className="invalid-feedback">{errors.recurrence.message?.toString()}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter price"
                  {...register("price", { required: "Price is required" })}
                  className={errors.price ? "is-invalid" : ""}
                />
                {errors.price && (
                  <div className="invalid-feedback">{errors.price.message?.toString()}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Most Popular</Form.Label>
                <select
                  {...register("isMostPopular", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errors.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="true">True</option>
                  <option value="false">false</option>
                </select>
                {errors.isMostPopular && (
                  <div className="invalid-feedback">{errors.isMostPopular.message?.toString()}</div>
                )}
              </Form.Group>
              
              <div className="d-flex justify-content-end align-items-center">
                <Button type="submit" variant="primary" className="me-2">
                  Save Plan
                </Button>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
              </div>

            </Form>
          </Modal.Body>

        </Modal>


        <Modal show={showUpdateModal} onHide={handleUpdateModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Subscription Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmitForm2(handleUpdateSubscription)}>
              <Form.Group className="mb-3">
                <Form.Label>Subscription Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter subscription plan name"
                  {...registerForm2("planName", { required: "Subscription plan name is required" })}
                  className={errorsForm2.planName ? "is-invalid" : ""}
                />
                {errorsForm2.planName && (
                  <div className="invalid-feedback">{errorsForm2.planName.message}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Recurrence</Form.Label>
                <select
                  {...registerForm2("recurrence", { required: "Recurrence is required" })}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.recurrence ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="monthly">Monthly</option>
                  <option value="yearly">Yearly</option>
                </select>
                {errorsForm2.recurrence && (
                  <div className="invalid-feedback">{errorsForm2.recurrence.message}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter price"
                  {...registerForm2("price", { required: "Price is required" })}
                  className={errorsForm2.price ? "is-invalid" : ""}
                />
                {errorsForm2.price && (
                  <div className="invalid-feedback">{errorsForm2.price.message}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Most Popular</Form.Label>
                <select
                  {...registerForm2("isMostPopular")}
                  className={`form-select c-form-control c-form-control-sm c-form-control-bg ${errorsForm2.isMostPopular ? "is-invalid" : ""
                    }`}
                  style={{ width: "100%" }}
                >
                  <option value="">{t("settings.tabVideoUpload.selectField.inputTitle")}</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                {errorsForm2.isMostPopular && (
                  <div className="invalid-feedback">{errorsForm2.isMostPopular.message}</div>
                )}
              </Form.Group>

              <div className="d-flex justify-content-end align-items-center">
                <Button type="submit" variant="primary" className="me-2">
                  Save Plan
                </Button>
                <Button variant="secondary" onClick={handleUpdateModalClose}>
                  Close
                </Button>
              </div>

            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          backdrop='static'
          centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to cancel the subscription?
          </Modal.Body>
          <Modal.Footer >

            <Button type="submit" className="w-25 btn btn-primary me-2" onClick={() => handleDeletePlan(selectedSubscriptionPlan)}>
              Yes
            </Button>
            <Button type="submit" className="w-25 btn btn-primary" onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SubscriptionManagement;
