import React, { useEffect } from 'react';
import './App.css';
import Navigation from "./navigation/index.jsx";
// import { useIntercom } from 'react-use-intercom';
import { UserProvider } from './context/UserContext';


function App() {
  const router = Navigation();
  document.title = 'Food Processing';
  // const { boot } = useIntercom()

  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   boot({ name: user?.name })
  // }, [boot])

  return (
    <>
        <UserProvider>
          <Navigation />
        </UserProvider>
    </>
  );
}

export default App;