import axiosInstance from "./api.ts";

async function httpClient(payload: any) {
  const payloadData = {
    ...payload,
  };
  try {
    const response = await axiosInstance(payloadData);
 
      const { data: result, headers } = response;
      return {
        result,
        headers,
        error: null,
      };

  } catch (error: any) {
    if (error?.response?.status === 401 && !payload.url.includes("login")) {
      // toast.error("Session Expired. Please login again.", { autoClose: 5000 });
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      window.location.href = '/#/'
    }
    let myError = error?.response ? error?.response?.data : error;
   

    // if (myError?.status) {
    //   if (myError.status === 400 && !payload.url.includes("login")) {
    //     // toast.error("Session Expired. Please login again.", {
    //     //   autoClose: 5000,
    //     // });
    //   } else {
    //     // toast.error(myError.message,{autoClose: 10000});
    //   }
    // } else {
    //   // toast.error(myError.message, { autoClose: 10000 });
    // }
    return {
      error: myError,
      result: null,
      headers: null,
    };
  }
}

export default httpClient;
