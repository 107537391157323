import React, { useEffect, useState } from "react";
// import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";


const PublicLayout = ({ component: Component }: any) => {


  const { pathname } = useLocation();

  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [pathname]);
  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      let parsedUser = JSON.parse(user);
      setUser(parsedUser);
      navigate("/employment-data");
    }
  }, []);

  if (user) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "70vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RotatingLines
          strokeColor="#279AF1"
          strokeWidth="3"
          animationDuration="0.75"
          width="52"
          visible={true}
        />
      </div>
    );
  }
  return (
    <>
      <main role="main">
        <div>
          <Component />
        </div>
      </main>
    </>
  );
};

export default PublicLayout;
