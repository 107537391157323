import React, { useEffect, useState } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import { useTranslation } from "react-i18next";
import CommonHeader from "../../components/Header.tsx";
import TabForEmployment from "../../components/TabForEmployment";
import { useUser } from "../../context/UserContext.js";
import { useNavigate } from "react-router-dom";

const EmploymentData = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isContextLoading, user, userPlan } = useUser()
  const [source, setSource] = useState()
  const [joyrideValue, setJoyrideValue] = useState(null);

  const getTab = (tab) => {
    switch (tab) {
      case 'tab1':
        setSource(`Source: ${t('dataSourceEmployment')}`)
        break;
      case 'tab2':
        setSource(`Source: ${t('dataSourceEmployment')}`)
        break;
      case 'tab3':
        setSource(`Source: ${t('dataSourceImmigration')}`)
        break;
    }
  }

  const updateSettings = (tabname, settingData) => {
    let userSetting = JSON.parse(localStorage.getItem('user'))
    let data

    switch (tabname) {
      case 'tabcomponent':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              tab: settingData
            },
          },
        };
        break;
      case 'provinceTerritory':

        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              provinceTerritory: settingData
            },
          },
        };
        break;
      case 'sectorYearly':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              sectorYearly: settingData
            },
          },
        };
        break;
      case 'years':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              years: settingData
            },
          },
        };
        break;
      case 'ageRange':
        data = {
          settings: {
            ...userSetting.settings,
            employment: {
              ...userSetting.settings.employment,
              ageRange: settingData
            },
          },
        };
        break;
      default:
        break;
    }

    let url = API_URLS.preloadedSettings;
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }

  const onStart = (value) => {
    setJoyrideValue(value);
  }

  useEffect(() => {
    if (!userPlan && !isContextLoading && user.role !== 'SUPER_ADMIN') {
      navigate('/subscription-plans')
    }
  }, [isContextLoading, userPlan]);

  useEffect(() => {
    setSource(`Source: ${t('dataSourceEmployment')}`)
  }, [t])

  return (
    <>
      {(userPlan || user?.role === "SUPER_ADMIN") &&
        <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>
          <CommonHeader
            title={t("employmentInsights.title")}
            subtitle={source}
            helpText={"workforceInsights.helpText"}
            onStart={onStart}
          />
          <div style={{ flex: 1 }}>
            <TabForEmployment getTabData={updateSettings} getTab={getTab} joyrideStatus={joyrideValue} setJoyrideStatus={setJoyrideValue} />
          </div>
        </div>
      }
    </>
  );
};

export default EmploymentData;
