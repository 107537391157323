import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/esm/Form";
import logo from "../assets/Logo.png";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import httpClient from "../services/httpClient.ts";
import { useNavigate } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import API_URLS from "./../constants/apiUrls.ts";

const hidden = {
  display: "none",
};

const SetPassword = () => {
  const {
    register,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
    watch, // To watch form inputs
  } = useForm({ mode: "all" });
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token')
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false); // State for new password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility

  // Watch for changes in newPassword and confirmPassword fields
  const newPassword = watch("newPassword", "");
  const confirmPassword = watch("confirmPassword", "");

  // Custom validation function to check if passwords match
  const validatePasswordMatch = (value) => {
    return value === newPassword || "Passwords do not match";
  };

  const handleSetPassword = (data) => {
    let url = API_URLS.setPassword;
    httpClient({
      method: "post",
      url,
      data:{password: data.newPassword, token},
    })
      .then(({ result, error }) => {

        if (result) {
          setSuccessMessage(result?.message);
          setTimeout(() => {
            navigate("/");
          }, 1500);
          reset();
        } else {
            setErrorMessage(error?.message);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container">
      <ToastContainer
        style={{ top: "20px", right: "20px" }}
        className="toast-error"
      >
        {errorMessage && (
          <Toast
            onClose={() => setErrorMessage(null)}
            show={true}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>{errorMessage}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
      <div className="row  no-gutters main-height">
        <div className="col-md-6 left-container">
          <img src={logo} alt="logo" width="150px" />
          <div className="w-500 mt-5">
            <p>
              Effortlessly upload and oversee <br /> your documents all in one
              place.
            </p>
          </div>
        </div>
        <div className=" align-items-center bg-white col-md-6 d-flex  p-5 ">
          <div className="brand  form-style">
            <h1 className="pb-3">Set Password</h1>
            <p className="info">Welcome!</p>
            <Form className="mb-5" style={successMessage ? hidden : {}}>
              <div className="form-group pb-3">
                <label>New Password *</label>
                <div className="input-group">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    className="form-control mt-2"
                    id="InputNewPassword"
                    {...register("newPassword", { required: "Required" })}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={toggleNewPasswordVisibility}
                    style={{ margin: "8px 0 0" }}
                  >
                    {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.newPassword && (
                  <div className="invalid-feedback">
                    {errors.newPassword.message}
                  </div>
                )}
              </div>
              <div className="form-group pb-3">
                <label>Confirm Password *</label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="form-control mt-2"
                    id="InputConfirmPassword"
                    {...register("confirmPassword", {
                      required: "Required",
                      validate: validatePasswordMatch,
                    })}
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={toggleConfirmPasswordVisibility}
                    style={{ margin: "8px 0 0", border: "" }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {errors.confirmPassword && (
                  <div className="invalid-feedback">
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-4"
                onClick={handleSubmit(handleSetPassword)}
              >
                Submit
              </button>
            </Form>
            {/* <div id="error-message" style={errorMessage ? {} : hidden}>
              <p style={{ color: "red" }} id="success">
                {errorMessage}
              </p>
            </div> */}
            <div id="success-message" style={successMessage ? {} : hidden}>
              <p style={{ color: "rgba(69, 230, 69, 0.836)" }} id="success">
                {successMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
