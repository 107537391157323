import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highmaps";
import canadaMap from "@highcharts/map-collection/countries/ca/ca-all.geo.json";
import HighchartsReact from "highcharts-react-official";
import { OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import { TableTitleForEducationProgram } from "../../constants/commonData";
import PaginationComponent from "./PaginationComponent";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter, capitalizeString, toCamelCase } from "../../services/commonFn";
import { FaGlobeAmericas, FaTable, FaArrowUp, FaArrowDown, FaInfoCircle, FaQuestionCircle } from "react-icons/fa";
import i18n from "i18next";
import ReactDOM from "react-dom";
import HelpTour from "../../components/HelpTour.tsx";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useWindowWidth from "../../hooks/useWindowWidth.js";
import ReactPlayer from "react-player";
import { BiSolidVideos } from 'react-icons/bi'

const TableViewEducationalProgram = ({ colData, countByGeography, steps, tabSettings, joyrideStatus, setJoyrideStatus }) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth()
  const scrollToEndRef = useRef(null);
  const clearAllRef = useRef(null);
  const [stepStates, setStepStates] = useState(
    steps?.map(() => ({ showVideo: false })) || []
  );
  const userSettings = JSON.parse(localStorage.getItem('user'))?.settings?.educationalPrograms?.provinceTerritory
  const [showTable, setShowtable] = useState(userSettings?.tableView ?? false);
  
  const [showPaginationComponent, setShowPaginationComponent] = useState(false);
  const [item, setItem] = useState('all');
  const [tableData, setTableData] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [studies, setStudies] = useState([]);
  const [study, setStudy] = useState("all");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [helpRun, setHelpRun] = useState(false)
  const [joyrideSteps, setSteps] = useState()
  const [joyrideStep, setJoyrideStep] = React.useState(0);
  const [columnSortOrder, setColumnSortOrder] = useState(
    Array(TableTitleForEducationProgram.length + 1).fill(true)
  );

  const handleHelpTour = (value) => {
    const { action, index, origin, status, type, step } = value;
    // Handle the reset or close actions (when the tour ends or is reset)
    if (action === 'reset' || action === 'close') {
      setHelpRun(false);
      setJoyrideStatus(false);
      resetStepStates()
    }
  };

  const resetStepStates = () => {
    setStepStates(steps?.map(() => ({ showVideo: false })) || [])
  }

  const handleClick = (item) => {
    setShowPaginationComponent(true);
    setShowButton(false);
    setItem(item);
    setSelectedProvince("");
  };

  const onBackClicked = () => {
    setShowPaginationComponent(false);
    setShowButton(true);
  };

  const sortTableData = (data, index, asc) => {
    const sortedData = [...data.slice(0, -2)]; // Exclude the last two rows
    sortedData.sort((a, b) => {
      if (index === 0) {
        const valueA = a[index].name; // Assuming column 1 is index 1
        const valueB = b[index].name;
        if (asc) {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      } else {
        const valueA = a[index]; // Assuming column 1 is index 1
        const valueB = b[index];
        if (asc) {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }
    });
    return [...sortedData, data[data.length - 2], data[data.length - 1]];
  };

  const handleSorting = (index) => {
    const newColumnSortOrder = [...columnSortOrder];
    newColumnSortOrder[index] = !newColumnSortOrder[index]; // Toggle sorting order for the clicked column
    setColumnSortOrder(newColumnSortOrder);

    const sortedData = sortTableData(
      tableData,
      index,
      newColumnSortOrder[index]
    ); // Pass the new sorting order
    setTableData(sortedData);
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setStudy(value);
    setItem(value === "all" ? "all" : studies.filter((item) => item.languageKey === value)[0]);
  };

  const [options, setOptions] = useState({
    chart: {
      map: canadaMap,
      height: 550,
      events: {
        load: function () {
          const chart = this;
          const iconContainerId = 'icon-container-map';
          const tooltipId = 'icon-tooltip-map';

          const addIconWithTooltip = () => {
            // Remove the existing icon and tooltip if they exist
            let existingIconContainer = document.getElementById(iconContainerId);
            if (existingIconContainer) existingIconContainer.remove();

            let existingTooltip = document.getElementById(tooltipId);
            if (existingTooltip) existingTooltip.remove();

            // Create a div for the icon
            let iconDiv = document.createElement('div');
            iconDiv.id = iconContainerId;
            iconDiv.className = 'custom-icon';

            // Ensure the chart container exists before appending elements
            if (chart.container) {
              // Set chart container position to relative
              chart.container.style.position = 'relative';
              chart.container.appendChild(iconDiv);
            }

            // Set the icon's position in the top-right corner
            const updateIconPosition = () => {
              if (chart.container) {
                iconDiv.style.right = '25px'; // 10px padding from right
                iconDiv.style.top = '10px'; // 10px padding from top
              }
            };

            updateIconPosition();
            Highcharts.addEvent(chart, 'redraw', updateIconPosition);
            window.addEventListener('resize', updateIconPosition);
          };

          addIconWithTooltip();
        }
      }
    },
    title: {
      text: "",
      align: "center",
    },
    subtitle: {
      text: "", // Add your subtitle text here
      style: {
        fontSize: '13px',
        fontWeight: 'bold'
      }
    },
    colorAxis: {
      min: 0,
      stops: [
        [0, "#faedee"],
        [0.5, "#f7929a"],
        [1, "#E61E28"],
      ],
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
      },
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.series.name}<br/>${t("province")}: ${this.point.name}<br/>${t("colleges")}: ${this.point.value}</b><br/><h4 style='font-weight:bold'>${t("clickForProgramDetails")}</h4>`
      }
    },
    series: [
      {
        name: "College Count",
        allAreas: false,
        states: {
          hover: {
            color: "#112144",
            borderColor: "#fff",
          },
        },
        data: [],
        dataLabels: {
          enabled: true,
          formatter: function () {
            return `${this.point.properties['hc-key']} <br> (${this.point.value})`
          },
          style: {
            border: "0",
            fontSize: "9px",
          },
          allowOverlap: true,
          align: "center"
        },
      },
    ],
    plotOptions: {
      series: {
        point: {
          events: {
            click: function () {
              setShowPaginationComponent(!showPaginationComponent);
              setSelectedProvince(this.properties.name.replace(/é/g, "e"));
            },
          },
        },
        cursor: "pointer"
      },
    },
  });

  const handlePaginationClearAll = () => {
    clearAllRef.current?.();
  }

  const clearAll = () => {
    setStudy('all')
    setItem('all');
  }

  useEffect(() => {
    if (joyrideStatus) {
      let joyrideSteps; // Initialize a variable to hold steps
      if (showPaginationComponent) {
        // Use slice to avoid mutating joyrideSteps
        joyrideSteps = steps?.slice(-4, -1)?.reverse() // Get steps from index 2 onward
        joyrideSteps = joyrideSteps?.map((step, sIndex) => {
          // console.log("sIndex" , sIndex);
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[sIndex]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === sIndex ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[sIndex]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content)?.split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            // Ensure to set the proper buttons
            disableCloseOnEsc: true, // Disable close on Esc if needed
            disableBeacon: true,
            showSkipButton: false, // Optionally hide the skip button
            showNextButton: true, // Show next button
            closeButtonText: "Close", // Change button text if needed
            placement: "left",
            floaterProps: {
              styles: {
                arrow: {
                  right: "-10px"
                }
              }
            },
            styles: {
              tooltip: {
                maxWidth: sIndex === 0 ? "75%" : "100%", // Ensure tooltip fits within the viewport
                left: sIndex === 0 ? "100px" : 'auto', // Dynamically adjust positioning
              },
              spotlight: {
                margin: "0", // Prevent excessive padding on spotlight
              },
              
            }
            // placement: window.innerWidth <= 768 ? 'top' : step.placement,
          }
        });
      } else if (!showTable) {
        joyrideSteps = steps?.slice(0, 4); // Get the first 3 steps
        joyrideSteps = joyrideSteps?.map((step, index) => {
          const contentWithVideo = (
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {!stepStates[index]?.showVideo && (
              <button
                onClick={() =>
                  setStepStates((prev) =>
                    prev.map((state, i) =>
                      i === index ? { ...state, showVideo: !state.showVideo } : state
                    )
                  )
                }
                style={{outline: 'none', border: 'none'}}
              >
                {<BiSolidVideos fontSize={20}/>}
              </button>
          )}
               {stepStates[index]?.showVideo && (
                <div
                  className="card"
                  style={{ height: "250px", width: "330px" }}
                >
                  <ReactPlayer
                    config={{
                      file: { attributes: { controlsList: "nodownload" } },
                    }}
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#020202",
                    }}
                    url={step?.videoUrl}
                    controls={true}
                    height="250px"
                    width="330px"
                  />
                </div>
              )}
              <br />
              <p>
                {t(step.content)
                  .split("\\n")
                  .map((line, idx) => (
                    <p key={idx}>{line}</p>
                  ))}
              </p>
            </div>
          );
          
          const translatedContent = (<>
            {t(step.content).split('\\n').map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: step?.videoUrl ? contentWithVideo : translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            // placement: window.innerWidth <= 768 ? 'top' : step.placement
          };
        })
      } else {
        joyrideSteps = [steps[steps?.length - 1]]; // Get the first 3 steps
        joyrideSteps = joyrideSteps?.map(step => {
          const translatedContent = (<>
            {t(step.content)?.split('\\n')?.map((line, index, array) => (
              <p key={index}>{line}</p>
            ))}
          </>)
          return {
            ...step,
            content: translatedContent,
            disableCloseOnEsc: true,
            disableBeacon: true,
            showSkipButton: false,
            showNextButton: true,
            closeButtonText: "Close",
            // placement: window.innerWidth <= 768 ? 'top' : step.placement
          };
        })
      }
      setSteps(joyrideSteps); // Set the steps state
      setHelpRun(true); // Set help run state
    }
  }, [joyrideStatus, stepStates]);

  useEffect(() => {
    (async () => {
      try {
        if (colData && colData.length > 0) {
          const tableData = colData.map((item, index) => {
            if (index < 12) {
              let rowData = [];
              rowData.push(item[0]);
              const extractedCounts = item
                .slice(1, 13)
                .map((obj, index) =>
                  obj.name === "" ? 0 : obj.count || obj[index]
                );
              extractedCounts.push(item[13]); // Adding the count value of the last object
              rowData = rowData.concat(extractedCounts);
              return rowData;
            } else {
              return item;
            }
          });

          setTableData(tableData);

          const studies = await Promise.all(
            colData
              .map(async (item) => {
                const [firstItem] = item;
                if (
                  firstItem &&
                  firstItem !== null &&
                  firstItem.languageKey !== undefined
                ) {
                  return firstItem;
                }
                return null;
              })
              .filter(Boolean)
          );

          setStudies(studies.filter((item) => item !== null));

          const firstStudy = studies[0];

          // setStudy(study ? study : firstStudy?.languageKey);
          // setItem(item ? item : firstStudy);

          if (item === 'all') {

            let seriesData = [];
            let sum = 0

            for (const feature of canadaMap.features) {
              const matchingData = countByGeography.find(
                (item) =>
                  item.geographyName ===
                  feature.properties.name?.replace(/é/g, "e").toLowerCase()
              );

              if (matchingData) {
                let languageKey = toCamelCase('province' + feature.properties.name.replace(/é/g, "e"))
                let dataLabelName = capitalizeString(t(languageKey));
                feature.properties["hc-key"] = dataLabelName;

                let yPosition = 0;
                let xPosition = 0
                if (windowWidth <= 900) {
                  if (languageKey === 'provincePrinceEdwardIsland') {
                    yPosition = -30 + 5;
                    xPosition = 12 - 30
                  } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                    yPosition = -40
                    xPosition = 35
                  } else if (languageKey === 'provinceNewBrunswick') {
                    yPosition = -8 + 3
                    xPosition = -7
                  } else if (languageKey === 'provinceNovaScotia') {
                    yPosition = 10
                    xPosition = 20 - 15
                  } else if (languageKey === 'provinceBritishColumbia') {
                    yPosition = -30
                    xPosition = -20 + 30
                  } else if (languageKey === 'provinceAlberta') {
                    yPosition = -25 + 15
                    xPosition = 0
                  } else if (languageKey === 'provinceSaskatchewan') {
                    yPosition = 15
                    xPosition = 0
                  } else if (languageKey === 'provinceManitoba') {
                    yPosition = -10 - 15
                    xPosition = 0 + 10
                  } else if (languageKey === 'provinceNunavut') {
                    yPosition = -15 - 50
                    xPosition = 15
                  } else if (languageKey === 'provinceNorthwestTerritories') {
                    yPosition = 0 - 10
                    xPosition = 15 + 20
                  } else if (languageKey === 'provinceYukon') {
                    yPosition = -20
                    xPosition = 0
                  } else if (languageKey === 'provinceQuebec') {
                    yPosition = -20 - 15
                    xPosition = 0
                  }
                } else {
                  if (languageKey === 'provincePrinceEdwardIsland') {
                    yPosition = -30;
                    xPosition = 12
                  } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                    yPosition = -40
                    xPosition = 35
                  } else if (languageKey === 'provinceNewBrunswick') {
                    yPosition = -8
                    xPosition = -7
                  } else if (languageKey === 'provinceNovaScotia') {
                    yPosition = 10
                    xPosition = 20
                  } else if (languageKey === 'provinceBritishColumbia') {
                    yPosition = -30
                    xPosition = -20
                  } else if (languageKey === 'provinceAlberta') {
                    yPosition = -25
                    xPosition = 0
                  } else if (languageKey === 'provinceSaskatchewan') {
                    yPosition = 15
                    xPosition = 0
                  } else if (languageKey === 'provinceManitoba') {
                    yPosition = -10
                    xPosition = 0
                  } else if (languageKey === 'provinceNunavut') {
                    yPosition = -15
                    xPosition = 15
                  } else if (languageKey === 'provinceNorthwestTerritories') {
                    yPosition = 0
                    xPosition = 15
                  }
                }

                const data = {
                  'hc-key': feature.properties["hc-key"],
                  name: dataLabelName,
                  value: matchingData.count,
                  dataLabels: {
                    y: yPosition,
                    x: xPosition
                  }
                }
                seriesData.push(data);
                sum += matchingData.count
              } else {
                if (feature.properties["hc-key"] !== "ca-5682") {
                  let languageKey = toCamelCase('province' + feature.properties.name)
                  let dataLabelName = capitalizeString(t(languageKey));
                  feature.properties["hc-key"] = dataLabelName;

                  let yPosition = 0;
                  let xPosition = 0
                  if (windowWidth <= 900) {
                    if (languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30 + 5;
                      xPosition = 12 - 30
                    } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (languageKey === 'provinceNewBrunswick') {
                      yPosition = -8 + 3
                      xPosition = -7
                    } else if (languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20 - 15
                    } else if (languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20 + 30
                    } else if (languageKey === 'provinceAlberta') {
                      yPosition = -25 + 15
                      xPosition = 0
                    } else if (languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (languageKey === 'provinceManitoba') {
                      yPosition = -10 - 15
                      xPosition = 0 + 10
                    } else if (languageKey === 'provinceNunavut') {
                      yPosition = -15 - 50
                      xPosition = 15
                    } else if (languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0 - 10
                      xPosition = 15 + 20
                    } else if (languageKey === 'provinceYukon') {
                      yPosition = -20
                      xPosition = 0
                    } else if (languageKey === 'provinceQuebec') {
                      yPosition = -20 - 15
                      xPosition = 0
                    }
                  } else {
                    if (languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30;
                      xPosition = 12
                    } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (languageKey === 'provinceNewBrunswick') {
                      yPosition = -8
                      xPosition = -7
                    } else if (languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20
                    } else if (languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20
                    } else if (languageKey === 'provinceAlberta') {
                      yPosition = -25
                      xPosition = 0
                    } else if (languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (languageKey === 'provinceManitoba') {
                      yPosition = -10
                      xPosition = 0
                    } else if (languageKey === 'provinceNunavut') {
                      yPosition = -15
                      xPosition = 15
                    } else if (languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0
                      xPosition = 15
                    }
                  }
                  const data = {
                    'hc-key': feature.properties["hc-key"],
                    name: dataLabelName,
                    value: 0,
                    dataLabels: {
                      y: yPosition,
                      x: xPosition
                    }
                  }

                  seriesData.push(data);
                  sum += 0
                }
              }
            }

            setOptions((prevOptions) => ({
              ...prevOptions, // Keep other properties unchanged
              title: {
                ...prevOptions.title,
                text: t("educationalProgram.tabProvinceAndTerritory.inputValue.totalPrograms")
              },
              subtitle: {
                ...prevOptions.subtitle,
                text: t('educationalProgram.tabProvinceAndTerritory.mapSubtitle') + sum, // Update the title text
              },
              series: [
                {
                  ...prevOptions.series[0], // Keep other series properties unchanged
                  data: seriesData, // Update the series data
                  name: t("educationalProgram.tabProvinceAndTerritory.mapSeriesName")
                },
              ],

            }));
          } else {
            const studyData = colData.find(
              (item) => item[0]?.languageKey == study
            );

            const geographyData = await Promise.all(
              studyData.slice(1, 12).map(async (item) => {
                if (item?.name && item.count > 0) {
                  return item;
                }
              })
            );

            const filteredGeographyData = geographyData.filter(Boolean);

            let seriesData = [];

            for (const feature of canadaMap.features) {
              const matchingData = filteredGeographyData.find(
                (item) =>
                  item.name ===
                  feature.properties.name?.replace(/é/g, "e").toLowerCase()
              );

              if (matchingData) {
                let languageKey = toCamelCase('province' + feature.properties.name.replace(/é/g, "e"))
                let dataLabelName = capitalizeString(t(languageKey));
                feature.properties["hc-key"] = dataLabelName;
                let yPosition = 0;
                let xPosition = 0
                if (windowWidth <= 900) {
                  if (languageKey === 'provincePrinceEdwardIsland') {
                    yPosition = -30 + 5;
                    xPosition = 12 - 30
                  } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                    yPosition = -40
                    xPosition = 35
                  } else if (languageKey === 'provinceNewBrunswick') {
                    yPosition = -8 + 3
                    xPosition = -7
                  } else if (languageKey === 'provinceNovaScotia') {
                    yPosition = 10
                    xPosition = 20 - 15
                  } else if (languageKey === 'provinceBritishColumbia') {
                    yPosition = -30
                    xPosition = -20 + 30
                  } else if (languageKey === 'provinceAlberta') {
                    yPosition = -25 + 15
                    xPosition = 0
                  } else if (languageKey === 'provinceSaskatchewan') {
                    yPosition = 15
                    xPosition = 0
                  } else if (languageKey === 'provinceManitoba') {
                    yPosition = -10 - 15
                    xPosition = 0 + 10
                  } else if (languageKey === 'provinceNunavut') {
                    yPosition = -15 - 50
                    xPosition = 15
                  } else if (languageKey === 'provinceNorthwestTerritories') {
                    yPosition = 0 - 10
                    xPosition = 15 + 20
                  } else if (languageKey === 'provinceYukon') {
                    yPosition = -20
                    xPosition = 0
                  } else if (languageKey === 'provinceQuebec') {
                    yPosition = -20 - 15
                    xPosition = 0
                  }
                } else {
                  if (languageKey === 'provincePrinceEdwardIsland') {
                    yPosition = -30;
                    xPosition = 12
                  } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                    yPosition = -40
                    xPosition = 35
                  } else if (languageKey === 'provinceNewBrunswick') {
                    yPosition = -8
                    xPosition = -7
                  } else if (languageKey === 'provinceNovaScotia') {
                    yPosition = 10
                    xPosition = 20
                  } else if (languageKey === 'provinceBritishColumbia') {
                    yPosition = -30
                    xPosition = -20
                  } else if (languageKey === 'provinceAlberta') {
                    yPosition = -25
                    xPosition = 0
                  } else if (languageKey === 'provinceSaskatchewan') {
                    yPosition = 15
                    xPosition = 0
                  } else if (languageKey === 'provinceManitoba') {
                    yPosition = -10
                    xPosition = 0
                  } else if (languageKey === 'provinceNunavut') {
                    yPosition = -15
                    xPosition = 15
                  } else if (languageKey === 'provinceNorthwestTerritories') {
                    yPosition = 0
                    xPosition = 15
                  }
                }
                const data = {
                  'hc-key': feature.properties["hc-key"],
                  name: dataLabelName,
                  value: matchingData.count,
                  dataLabels: {
                    y: yPosition,
                    x: xPosition
                  }
                }

                seriesData.push(data);
              } else {
                if (feature.properties["hc-key"] !== "ca-5682") {
                  let languageKey = toCamelCase('province' + feature.properties.name)
                  let dataLabelName = capitalizeString(t(languageKey));
                  feature.properties["hc-key"] = dataLabelName;
                  let yPosition = 0;
                  let xPosition = 0
                  if (windowWidth <= 900) {
                    if (languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30 + 5;
                      xPosition = 12 - 30
                    } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (languageKey === 'provinceNewBrunswick') {
                      yPosition = -8 + 3
                      xPosition = -7
                    } else if (languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20 - 15
                    } else if (languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20 + 30
                    } else if (languageKey === 'provinceAlberta') {
                      yPosition = -25 + 15
                      xPosition = 0
                    } else if (languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (languageKey === 'provinceManitoba') {
                      yPosition = -10 - 15
                      xPosition = 0 + 10
                    } else if (languageKey === 'provinceNunavut') {
                      yPosition = -15 - 50
                      xPosition = 15
                    } else if (languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0 - 10
                      xPosition = 15 + 20
                    } else if (languageKey === 'provinceYukon') {
                      yPosition = -20
                      xPosition = 0
                    } else if (languageKey === 'provinceQuebec') {
                      yPosition = -20 - 15
                      xPosition = 0
                    }
                  } else {
                    if (languageKey === 'provincePrinceEdwardIsland') {
                      yPosition = -30;
                      xPosition = 12
                    } else if (languageKey === 'provinceNewfoundlandAndLabrador') {
                      yPosition = -40
                      xPosition = 35
                    } else if (languageKey === 'provinceNewBrunswick') {
                      yPosition = -8
                      xPosition = -7
                    } else if (languageKey === 'provinceNovaScotia') {
                      yPosition = 10
                      xPosition = 20
                    } else if (languageKey === 'provinceBritishColumbia') {
                      yPosition = -30
                      xPosition = -20
                    } else if (languageKey === 'provinceAlberta') {
                      yPosition = -25
                      xPosition = 0
                    } else if (languageKey === 'provinceSaskatchewan') {
                      yPosition = 15
                      xPosition = 0
                    } else if (languageKey === 'provinceManitoba') {
                      yPosition = -10
                      xPosition = 0
                    } else if (languageKey === 'provinceNunavut') {
                      yPosition = -15
                      xPosition = 15
                    } else if (languageKey === 'provinceNorthwestTerritories') {
                      yPosition = 0
                      xPosition = 15
                    }
                  }
                  const data = {
                    'hc-key': feature.properties["hc-key"],
                    name: dataLabelName,
                    value: 0,
                    dataLabels: {
                      y: yPosition,
                      x: xPosition
                    }
                  }
                  seriesData.push(data);
                }
              }
            }

            setOptions((prevOptions) => ({
              ...prevOptions, // Keep other properties unchanged
              title: {
                ...prevOptions.title,
                text: t('educationalProgram.tabProvinceAndTerritory.mapTitle') + capitalizeString(t(study)), // Update the title text
              },
              subtitle: {
                ...prevOptions.subtitle,
                text: t('educationalProgram.tabProvinceAndTerritory.mapSubtitle') + studyData[13], // Update the title text
              },
              series: [
                {
                  ...prevOptions.series[0], // Keep other series properties unchanged
                  data: seriesData, // Update the series data
                  name: t("educationalProgram.tabProvinceAndTerritory.mapSeriesName")
                },
              ],
            }));
          }


        }
      } catch (error) {
        console.error("Error:", error);
      }
    })();
  }, [colData, item, selectedProvince, study, windowWidth, t]);

  return (
    <>
      <HelpTour
        run={helpRun}
        steps={joyrideSteps}
        onJoyrideCallback={handleHelpTour}
        scrollToSteps={true}
      // setStepIndex={handlePaginationHelpTour}
      />
      <div className="d-flex justify-content-between">
        <h6 className="py-3 px-2">
          {t("educationalProgram.tabProvinceAndTerritory.title")}
        </h6>

      </div>
      {showPaginationComponent && (
        <div className="row gx-0">
          <div className="d-flex justify-content-between">
            <button
              onClick={() => { setShowPaginationComponent(!showPaginationComponent); setStepStates(steps?.map(() => ({ showVideo: false })) || []) }}
              className="btn btn-link text-theme font-small ps-2 m-0"
            >
              {showTable
                ? t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.backButtonTextTable"
                )
                : t(
                  "educationalProgram.tabProvinceAndTerritory.paginationComponent.backButtonTextGeography"
                )}
            </button>


            <button className="clear_btn" onClick={handlePaginationClearAll} style={{ fontSize: "15px", marginRight: "10px" }}>
              {t("commonSubHeadingClearAll")}
            </button>
          </div>
        </div>
      )
      }

      <div
        className={`d-flex ${!showTable ? "justify-content-between" : "justify-content-end"} flex-wrap`}
        style={{
          paddingBottom: !showTable ? "15px" : "0",
        }}
      >
        {!showTable && (
          <div className="col-12 col-md-3 mb-2 mb-md-0">
            <div className="custom-form-group custom-form-group-sm ps-2">
              <label htmlFor="yearSelect" className="text-black">
                {t('educationalProgram.tabProvinceAndTerritory.paginationComponent.inputTitle.selectAreaOfStudy')}
              </label>
              <select
                className="form-select c-form-control fw-semibold c-form-control-md c-form-control-bg"
                value={study}
                onChange={handleSelectChange}
              >
                <option value={""} disabled>
                  {t('educationalProgram.tabProvinceAndTerritory.inputValue.select')}
                </option>
                <option value={"all"}>
                  {t("educationalProgram.tabProvinceAndTerritory.inputValue.totalPrograms")}
                </option>
                {studies &&
                  studies.map((study) => (
                    <option key={study._id} value={study.languageKey}>
                      {capitalizeString(t(study.languageKey))}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
        {!showPaginationComponent && (
          <div className="col-12 col-md-4 d-flex justify-content-end align-items-center">
            {!showTable && (
              <>
                <button
                  className="clear_btn"
                  onClick={clearAll}
                  style={{ fontSize: "15px", marginRight: "10px" }}
                >
                  {t("commonSubHeadingClearAll")}
                </button>
                <div className="vertical-line"></div>
              </>
            )}
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>Globe View</Tooltip>
              )}
              placement="top"
            >
              <button
                onClick={() => {
                  setShowtable(false);
                  tabSettings("provinceTerritory", { tableView: false });
                  setItem(tableData[0][0]);
                  setStudy(studies[0]?.languageKey);
                }}
                style={{
                  outline: "none",
                  border: "1px solid #dee2e6",
                  borderRadius: "4px 0 0 4px",
                  width: "40px",
                  height: "40px",
                  backgroundColor: !showTable ? "#666666" : "#f0f0f0",
                  color: !showTable ? "#fff" : "",
                }}
                className="btn-responsive"
              >
                <FaGlobeAmericas />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>Tabular View</Tooltip>
              )}
              placement="top"
            >
              <button
                onClick={() => {
                  setShowtable(true);
                  tabSettings("provinceTerritory", { tableView: true });
                }}
                style={{
                  outline: "none",
                  border: "1px solid #dee2e6",
                  borderRadius: "0 4px 4px 0",
                  width: "40px",
                  height: "40px",
                  backgroundColor: showTable ? "#666666" : "#f0f0f0",
                  color: showTable ? "#fff" : "",
                }}
                className="btn-responsive"
              >
                <FaTable />
              </button>
            </OverlayTrigger>
          </div>
        )}
      </div>

      <div style={{ padding: "10px" }}>

        {showPaginationComponent ? (
          <PaginationComponent
            onBackClicked={onBackClicked}
            item={item}
            showTable={showTable}
            province={selectedProvince}
            clearAllRef={clearAllRef}
            scrollToEndRef={scrollToEndRef}
          />
        ) : (
          <>
            {showTable ? (
              <Table
                responsive
                striped
                bordered
                className="custom-educational-table m-0"
              >
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSorting(0)}
                      style={{
                        align: "center",
                        backgroundColor: "#e41b2c",
                        borderRadius: "5px 0 0 0",
                      }}
                    >
                      {t(
                        "educationalProgram.tabProvinceAndTerritory.tableColumnHeading.AreaOfStudy"
                      )}
                      {columnSortOrder[0] ? (
                        <FaArrowDown
                          style={{ float: "right", fontSize: "10px" }}
                        />
                      ) : (
                        <FaArrowUp
                          style={{ float: "right", fontSize: "10px" }}
                        />
                      )}
                    </th>
                    {TableTitleForEducationProgram.map((title, index) => (
                      <th
                        key={index}
                        style={{
                          textAlign: "center",
                          backgroundColor: "#e41b2c",
                        }}
                        onClick={() => handleSorting(index + 1)}
                      >
                        {t(title.languageKey)}
                        {columnSortOrder[index + 1] ? (
                          <FaArrowUp
                            style={{ float: "right", fontSize: "10px" }}
                          />
                        ) : (
                          <FaArrowDown
                            style={{ float: "right", fontSize: "10px" }}
                          />
                        )}
                      </th>
                    ))}
                    <th
                      style={{
                        textAlign: "center",
                        backgroundColor: "#e41b2c",
                        borderRadius: "0 5px 0 0",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((rowData, index1) => (
                    <tr key={index1}>
                      {rowData?.map((item, index) => (
                        <>
                          {index === 0 && index1 !== 13 && index1 !== 12 ? (
                            <OverlayTrigger
                              key={`${index1}-${index}`}
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {item?.languageKey
                                    ? capitalizeFirstLetter(t(item.languageKey))
                                    : item}
                                  <br /> {t('educationalProgram.tabProvinceAndTerritory.tooltipText')}
                                </Tooltip>
                              )}
                              placement="bottom"
                            >
                              <td
                                key={`${index1}-${index}`}
                                onClick={
                                  index === 0 && index1 !== 13 && index1 !== 12
                                    ? () => handleClick(item)
                                    : () => { }
                                }
                                className={index === 0 ? "data-column" : ""}
                                style={{
                                  cursor:
                                    index === 0 &&
                                      index1 !== 13 &&
                                      index1 !== 12
                                      ? "pointer"
                                      : "",
                                  textAlign: index !== 0 ? "center" : "left",
                                  fontWeight:
                                    index === 13 || index1 === 13 ? "bold" : "",
                                  fontSize:
                                    index1 === 13 || index === 13
                                      ? "14px"
                                      : "12px",
                                }}
                              >
                                <span>
                                  {item?.languageKey
                                    ? capitalizeFirstLetter(t(item.languageKey))
                                    : item}
                                </span>
                              </td>
                            </OverlayTrigger>
                          ) : (
                            <td
                              key={`${index1}-${index}`}
                              onClick={
                                index === 0 && index1 !== 13 && index1 !== 12
                                  ? () => handleClick(item)
                                  : () => { }
                              }
                              style={{
                                cursor:
                                  index === 0 && index1 !== 13 && index1 !== 12
                                    ? "pointer"
                                    : "",
                                textAlign: index !== 0 ? "center" : "left",
                                fontWeight:
                                  index === 13 || index1 === 13 ? "bold" : "",
                                fontSize:
                                  index1 === 13 || index === 13
                                    ? "14px"
                                    : "12px",
                              }}
                            >
                              <span>{item}</span>
                            </td>
                          )}
                        </>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="education-tabProvince-map">
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"mapChart"}
                  options={options}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TableViewEducationalProgram;

const CustomIconWithTooltip = ({ iconContainerId, tooltipId, startHelpTour }) => {
  const { t } = useTranslation()
  return (
    <>
      <div id={`${iconContainerId}-2`} className="custom-icon" style={{ position: 'relative' }}>
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip {...props} className="tooltip-justify">
              {t("helpTextEducationInsightMap").split('\\n').map((line, index, array) => (
                <React.Fragment key={index}>
                  {line}
                  {index < array.length - 1 && (
                    <div style={{ height: '7px' }} />)}
                </React.Fragment>
              ))}
            </Tooltip>
          )}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              width: "30px",
              height: "30px",
              backgroundColor: "#f0f0f0",
              cursor: 'pointer'
            }}
          >
            <FaQuestionCircle style={{ fontSize: '20px', color: "#212529" }} onClick={() => startHelpTour()} />
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
};
