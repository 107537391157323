import React, { useEffect, useState } from "react";
import API_URLS from "../../constants/apiUrls.ts";
import httpClient from "../../services/httpClient.ts";
import { capitalizeFirstLetter } from "../../services/commonFn";
import CommonHeader from "../../components/Header.tsx";
import TabComponent from "../../components/TabComponent";
import { TableTitleForEducationProgram } from "../../constants/commonData";
import { useTranslation } from "react-i18next";
import { useUser } from "../../context/UserContext.js";
import { useNavigate } from "react-router-dom";

const EducationalProgram = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isContextLoading, user, userPlan } = useUser()
  const [joyrideValue, setJoyrideValue] = useState()
  const [colData, setColData] = useState([]);
  const [countByGeography, setCountByGeography] = useState(null)
  const [areaOfStudy, setAreaOfStudy] = useState([]);
  const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem('user')).settings.educationalPrograms.tab || "tab1")
  const [programCountByAreaOfStudy, setProgramCountByAreaOfStudy] = useState(
    []
  );

  useEffect(() => {
    if (!userPlan && !isContextLoading && user.role !== 'SUPER_ADMIN') {
      navigate('/subscription-plans')
    }
  }, [isContextLoading, userPlan]);

  const updateSettings = (tabname, settingData) => {
    let userSetting = JSON.parse(localStorage.getItem('user'))
    let data

    switch (tabname) {
      case 'tabcomponent':
        data = {
          settings: {
            ...userSetting.settings,
            educationalPrograms: {
              ...userSetting.settings.educationalPrograms,
              tab: settingData
            },
          },
        };
        setActiveTab(settingData)
        break;
      case 'provinceTerritory':
        data = {
          settings: {
            ...userSetting.settings,
            educationalPrograms: {
              ...userSetting.settings.educationalPrograms,
              provinceTerritory: settingData
            },
          },
        };
        break;
      default:
        break;
    }

    let url = API_URLS.preloadedSettings;
    httpClient({
      method: "put",
      url,
      data,
    }).then(({ result, error }) => {
      if (result) {
        let datatoSave = {
          ...userSetting,
          settings: {
            ...result?.settings,
          },
        };
        localStorage.setItem("user", JSON.stringify(datatoSave));
      }
    });
  }

  useEffect(() => {
    (async () => {
      try {
        let url = API_URLS.educationalProgram;

        await httpClient({
          method: "get",
          url,
        }).then(({ result, error }) => {
          if (result) {
            const res = result;
            const countArray = res?.countsByGeography.map(item => item)
            setCountByGeography(countArray);
            const resArr = [];
            const grandTotal = [];
            const distribution = [];
            for (let c in res.counts) {
              let newarr = new Array(14).fill(0);
              let temparr = newarr.map(() => ({ name: "", count: 0 }));
              let sum = 0;
              temparr[0] = {
                name: capitalizeFirstLetter(res.counts[c].fieldOfStudy.name),
                id: res.counts[c].fieldOfStudy._id,
                languageKey: res.counts[c].fieldOfStudy.languageKey
              };
              for (
                let i = 0;
                i < res.counts[c].fieldOfStudy.geographies.length;
                i++
              ) {
                let geoArr = res.counts[c].fieldOfStudy.geographies[i];
                let findMyIndex = TableTitleForEducationProgram.findIndex(
                  (x) => x.name === geoArr?.name
                );
                if (findMyIndex !== -1)
                  temparr[findMyIndex + 1] = {
                    name: res.counts[c].fieldOfStudy.geographies[i]?.name,
                    languageKey: res.counts[c].fieldOfStudy.geographies[i]?.languageKey,
                    count: res.counts[c].fieldOfStudy.geographies[i]?.count
                  }
                sum += res.counts[c].fieldOfStudy.geographies[i].count;
              }
              temparr[13] = sum;
              resArr[c] = temparr;
            }
            let totalSum = 0;
            grandTotal[0] = "Grand Total";
            distribution[0] = "Distribution";
            for (let i in res.countsByGeography) {
              grandTotal[Number(i) + 1] = res.countsByGeography[i].count;
              totalSum += res.countsByGeography[i].count;
            }
            grandTotal[res.countsByGeography.length + 1] = totalSum;
            distribution[res.countsByGeography.length + 1] = "100%";
            for (let i in res.countsByGeography) {
              distribution[Number(i) + 1] =
                ((res.countsByGeography[i].count / totalSum) * 100).toFixed(1) +
                "%";
            }

            let areaOfStudy = [];
            let programCountByAreaOfStudy = [];
            for (let countObj of res.countsByAreaOfStudy) {
              areaOfStudy.push(capitalizeFirstLetter(t(countObj.fieldOfLanguageKey)));
              programCountByAreaOfStudy.push(countObj.count);
            }
            setAreaOfStudy(areaOfStudy);
            setProgramCountByAreaOfStudy(programCountByAreaOfStudy);

            resArr[res.counts.length] = grandTotal;
            resArr[res.counts.length + 1] = distribution;

            setColData([...resArr]);
          }
        });

      } catch (error) {

      }
    })()
  }, []);

  const onStart = () => {
    const table = document.querySelector(".table-responsive");
    const lastTd = document.querySelector("#last-td");
    if (table && lastTd) {
      table.scrollIntoView({ behavior: "auto", block: "start" });
      const offsetLeft = lastTd.offsetLeft;
      const scrollPadding = 10;
      table.scrollTo({
        left: offsetLeft - scrollPadding,
        behavior: "auto",
      });
    }
    setTimeout(() => {
      setJoyrideValue(true);
    }, 200);
  }

  return (
    <>
      {(userPlan || user?.role === "SUPER_ADMIN") &&
        <div className="main-content" style={{ display: 'flex', flexDirection: 'column', marginBottom: "10px" }}>

          <CommonHeader
            title={t('educationalProgram.title')}
            subtitle={`Source: ${t('dataSourceEducation')}`}
            helpText={
              activeTab === "tab1"
                ? "educationalProgram.tabProvinceAndTerritory.helpText"
                : "educationalProgram.helpText"
            }
            onStart={onStart}
          />


          <div style={{ flex: 1 }}>
            <TabComponent
              countByGeography={countByGeography}
              colData={colData}
              areaOfStudy={areaOfStudy}
              programCountByAreaOfStudy={programCountByAreaOfStudy}
              getTabData={updateSettings}
              joyrideStatus={joyrideValue}
              setJoyrideStatus={setJoyrideValue}
            />
          </div>
        </div>
      }
    </>
  );
};

export default EducationalProgram;

