import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Alert } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import httpClient from '../services/httpClient.ts';
import API_URLS from '../constants/apiUrls.ts';
import { ToastContainer, toast } from 'react-toastify';
import RightCheck from '../assets/circle.png'
import logoblack from "../assets/fpsc-logo.png";
import { Loader } from '../components/Loader.tsx';



const VerifyEmail: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    // const [showModal, setShowModal] = useState<boolean>(false);
    const [status, setStatus] = useState('')
    
    const handleResendToken = async () => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const data = {
            token
        }
        httpClient({
            method: "post",
            url: API_URLS.resendToken,
            data,
        })
            .then(({ result }) => {
                if (result.status) {
                    // setSuccess(true)
                    // const status = determineStatus(result)
                    setStatus('verificationLinkSent')
                } else {
                    setError(result.message)
                    // toast.error(result.message)
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error(error.message);
            });
        }
    const determineStatus = (apiResult) => {
        if (apiResult.linkExpired) {
            return "expired"; // Link expired
        } else if (apiResult.isEmailVerified) {
            return "verified"; // Email already verified or verification successful
        } else if (apiResult.emailVerified) {
            return "success"; // Email already verified or verification successful
        } else {
            return "error"; // Email not verified or other failure
        }
    };
    const verifyEmail = async () => {
        setIsLoading(true); // Directly set to true when the verification starts
        try {
            // Extract token from query parameters
            const params = new URLSearchParams(location.search);
            const token = params.get("token");
            if (!token) {
                setIsLoading(false); // Set loading to false if token is missing
                throw new Error("Verification token is missing.");
            }
            const data = { token };
            // Await the HTTP request instead of using `.then()` for better async handling
            const { result } = await httpClient({
                method: "post",
                url: API_URLS.verifyNewUserToken,
                data,
            });
            if (result.status) {
                const status = determineStatus(result);
                    setIsLoading(false)
                    setStatus(status); // Delay updating status   
            } else {
                setError(result.message);
            }
        } catch (err) {
            setError((err as Error).message);
            toast.error((err as Error).message);
        } 
        // finally {
        //     console.log("Setting loading to false...");
        //     console.log(isLoading, 'loader')
        //     setIsLoading(false); // Set loading to false after the process completes
        // }
    };
    useEffect(() => {
        verifyEmail();
    }, [location.search]); // Add location.search as a dependency
    
    //   const handleCloseModal = () => {
    //     setShowModal(false);
    //   };
    const styles = {
        card: {
            width: "450px",
            //   height: "500px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "20px",
            textAlign: "center",
        },
        icon: {
            fontSize: "60px",
            marginBottom: "20px",
        },
        heading: {
            fontSize: "24px",
            fontWeight: "bold",
            marginBottom: "10px",
        },
        text: {
            fontSize: "16px",
            marginBottom: "20px",
        },
        button: {
            padding: "10px 20px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
        },
        successButton: {
            backgroundColor: "#28a745",
            color: "#fff",
        },
        errorButton: {
            backgroundColor: "#dc3545",
            color: "#fff",
        },
        expiredButton: {
            backgroundColor: "#007bff",
            color: "#fff",
        },
    };
    const SuccessCard = () => (
        <div style={styles.card}>
            <div className="check-animation" style={styles.icon}>
                <img
                    src={RightCheck}// Replace with the actual path to your animated cross icon
                    alt="Error Icon"
                    style={{ width: "80px", height: "80px" }} // Adjust the size as needed
                />
            </div>
            <h2 style={styles.heading}>Email Verified Successfully!</h2>
            <p style={styles.text}>Your account successfully activated. You can now log in to your account.</p>
            <button style={{ ...styles.button, ...styles.successButton }} onClick={() => navigate('/')}>Go to Login</button>
        </div>
    );
    const VerifiedCard = () => (
        <div style={styles.card}>
            <div className="error-animation" style={styles.icon}>
                ❌ {/* Replace with an animated error cross image */}
            </div>
            <h2 style={styles.heading}>Link Expired</h2>
            <p style={styles.text}>Your mail is already verified, You can now log in to your account.</p>
            <button style={{ ...styles.button, ...styles.errorButton }} onClick={() => navigate('/')}>Go to Login</button>
        </div>
        // <div style={styles.card}>
        //     <div className="check-animation" style={styles.icon}>
        //         <img
        //             src={RightCheck}// Replace with the actual path to your animated cross icon
        //             alt="Error Icon"
        //             style={{ width: "80px", height: "80px" }} // Adjust the size as needed
        //         />
        //     </div>
        //     <h2 style={styles.heading}>Email Already Verified!</h2>
        //     <p style={styles.text}>Your account is activated. You can log in to your account.</p>
        //     <button style={{ ...styles.button, ...styles.successButton }} onClick={() => navigate('/')}>Go to Login</button>
        // </div>
    );
    const EmailResendTokenCard = () => (
        <div style={styles.card}>
            <div className="check-animation" style={styles.icon}>
                <img
                    src={RightCheck}// Replace with the actual path to your animated cross icon
                    alt="Error Icon"
                    style={{ width: "80px", height: "80px" }} // Adjust the size as needed
                />
            </div>
            <h2 style={styles.heading}>Email Sent Successfully!</h2>
            <p style={styles.text}>Please check your email to verify your account and activate it.</p>
            <button style={{ ...styles.button, ...styles.successButton }} onClick={() => navigate('/')}>Go to Login</button>
        </div>
    );
    const ExpiredCard = () => (
        <div style={styles.card}>
            <div className="expired-icon" style={styles.icon}>
                ⌛ {/* Replace with an appropriate expired icon */}
            </div>
            <h2 style={styles.heading}>Link Expired</h2>
            <p style={styles.text}>The verification link has expired. Please request a new one.</p>
            <button style={{ ...styles.button, ...styles.expiredButton }} onClick={() => handleResendToken()}>Resend Verification Link</button>
        </div>
    );
    const ErrorCard = () => (
        <div style={styles.card}>
            <div className="error-animation" style={styles.icon}>
                ❌ {/* Replace with an animated error cross image */}
            </div>
            <h2 style={styles.heading}>Verification Failed</h2>
            <p style={styles.text}>There was an error verifying your email. Please try again later.</p>
            <button style={{ ...styles.button, ...styles.errorButton }}>Try Again</button>
        </div>
    );
    return (
        <>
            <ToastContainer />
            <Loader show={isLoading}/>
            <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "100vh", backgroundColor: "#f8f9fa", position: 'relative' }}>
                <div className="" style={{ position: "absolute", left: 0, top: 0, margin: "20px 0 0 10px" }}>
                    <div className="container-fluid">
                        <img src={logoblack} alt="" style={{ width: "154px" }} />
                    </div>
                </div>
                {status === "success" && <SuccessCard />}
                {status === "expired" && <ExpiredCard />}
                {status === "verified" && <VerifiedCard />}
                {status === "verificationLinkSent" && <EmailResendTokenCard/>}
                {status === "error" && <ErrorCard />}
            </div>
        </>
    );
};
export default VerifyEmail;